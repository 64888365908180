


















import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';

const namespace: string = 'user';

@Component
export default class NavBar extends Vue {
  @Action('logout', { namespace }) private logout?: () => void;
  @Mutation('toggleMenu') private toggleMenu: () => void;
  @State('wave') private waveState?: any;

  public docUrl(): string {
    return this.waveState.wave.imei != null
      ? './docs/000AWG200_001.pdf'
      : './docs/000AWG100_001.pdf';
  }
}
