
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { UserState, User } from '@/store/user/types';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';
import Input from '@/components/Input.vue';
import RegisterFormForm from '@/components/RegisterFormForm.vue';

const namespace: string = 'user';

@Component({
  components: {
    Alert,
    Input,
    RegisterFormForm,
  },
})
export default class Account extends Vue {
  // @State('alert') private alertState?: any;
  @State('user') private userState?: any;
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @Mutation('alert/HIDE') private alertOff: () => void;
  @Action('password', { namespace }) private password: (
    user: User,
  ) => Promise<undefined>;
  @Action('email', { namespace }) private email: (
    user: User,
  ) => Promise<undefined>;
  @Action('delete', { namespace }) private delete: () => void;
  @Action('get', { namespace }) private get: () => void;
  @Action('update', { namespace }) private update: (user: User) => Promise<any>;

  onSubmit() {
    this.update(this.userState.user).then(() => this.get());
  }

  public userPassword: User = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  public userEmail: User = {
    email: '',
    confirmEmail: '',
  };

  public mounted() {
    this.get();
  }

  private onChangePassword() {
    if (
      this.userPassword.oldPassword! !== '' &&
      this.userPassword.newPassword! !== '' &&
      this.userPassword.confirmPassword! !== ''
    ) {
      if (
        this.userPassword.newPassword! === this.userPassword.confirmPassword!
      ) {
        this.password(this.userPassword)
          .then(() => {
            this.userPassword.oldPassword = '';
            this.userPassword.newPassword = '';
            this.userPassword.confirmPassword = '';
          })
          .catch(() => {});
      } else {
        this.alert({
          prefix: 'INPUT_',
          error: 'NOT_SAME',
        });
      }
    } else {
      this.alert({
        prefix: 'INPUT_',
        error: 'EMPTY',
      });
    }
  }

  private onChangeEmail() {
    if (this.userEmail.email! !== '' && this.userEmail.confirmEmail! !== '') {
      if (this.userEmail.email! === this.userEmail.confirmEmail!) {
        this.email(this.userEmail)
          .then(() => {
            this.userEmail.email = '';
            this.userEmail.confirmEmail = '';
          })
          .catch(() => {});
      } else {
        this.alert({
          prefix: 'INPUT_EMAIL_',
          error: 'NOT_SAME',
        });
      }
    } else {
      this.alert({
        prefix: 'INPUT_',
        error: 'EMPTY',
      });
    }
  }

  private onDelete() {
    let Conf = confirm('Voulez-vous vraiment supprimer votre compte ?');
    if (Conf === true) {
      this.delete();
    }
  }
}
