import { Error, RootState } from '@/store/types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { LogDocument, LogState } from './types';

export const actions: ActionTree<LogState, RootState> = {
  get(
    { commit, state },
    data: {
      idDevice: string;
      page: number;
      verbose: boolean;
      pageSize: number;
    },
  ): void {
    axios({
      url: `/api/v2/devices/${data.idDevice}/logs?page=${data.page}&pageSize=${
        data.pageSize
      }&verbose=${data.verbose ? 1 : 0}`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: LogDocument[] = response && response.data;
        if (data.page > 1) commit('LOG_SUCCESS_MORE', payload);
        else commit('LOG_SUCCESS', payload);

        if (payload.length < data.pageSize)
          commit('LOG_SUCCESS_NO_MORE', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('LOG_ERROR', payload);
      },
    );
  },
};
