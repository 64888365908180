



































import Alert from '@/components/Alert.vue';
import DropWave from '@/components/wave/DropWave.vue';
import NavWave from '@/components/wave/NavWave.vue';
import NavWave2 from '@/components/wave/NavWave2.vue';
import AlarmHistoryView from '@/components/wave/view/AlarmHistoryView.vue';
import AutoView from '@/components/wave/view/AutoView.vue';
import ConfigView from '@/components/wave/view/ConfigView.vue';
import InputView from '@/components/wave/view/InputView.vue';
import MapView from '@/components/wave/view/MapView.vue';
import OutputView from '@/components/wave/view/OutputView.vue';
import TemperatureView from '@/components/wave/view/TemperatureView.vue';
import WaitingDownlink from '@/components/wave/WaitingDownlink.vue';
import i18n from '@/i18n';
import store from '@/store/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

export interface NavWaveConfig {
  name?: string;
  component?: string;
  data?: any;
  icon?: string;
  spacer?: boolean;
  is?: string;
  no?: number;
}

const namespace: string = 'wave';

@Component({
  components: {
    NavWave,
    NavWave2,
    DropWave,
    WaitingDownlink,
    InputView,
    OutputView,
    TemperatureView,
    MapView,
    AutoView,
    ConfigView,
    AlarmHistoryView,
    Alert,
  },
})
export default class WavePage extends Vue {
  @State('wave') private waveState?: any;
  @State('alert') private alertState?: any;
  @Action('get', { namespace }) private get: (_id: string) => void;
  @Action('getAlarm', { namespace }) private getAlarm: (_id: string) => void;
  @Prop(String) private _idDevice: string;

  private navWaveSelectedIndex: number = 0;
  private data: any = null;
  private no?: number = 0;
  private view: string | null = null;

  private onClick(index: number, config: NavWaveConfig) {
    let canContinue = true;
    if (store.state.dataChangedCounter > 1) {
      canContinue = confirm(i18n.t('confirm_data_changed').toString());
    }

    if (canContinue) {
      store.commit('dataChangedReset');
      if (this.waveState && this.waveState.wave && this.waveState.wave._id)
        this.get(this.waveState.wave._id);

      this.view = config.component || '';
      this.data = config.data;
      this.no = config.no;
      this.navWaveSelectedIndex = index;
    }
  }

  @Watch('_idDevice')
  getWave() {
    this.get(this._idDevice);
  }

  @Watch('waveState.wave', { deep: true })
  dataChanged() {
    store.commit('dataChanged');
  }

  private getName(name: string, fallback: string): string {
    return name.trim().length > 0 ? name : fallback;
  }

  getConfig(): NavWaveConfig[] {
    return [
      {
        name: this.getName(
          this.waveState.wave.inputs[0].name,
          i18n.t('default-input1-name').toString(),
        ),
        component: 'InputView',
        is: 'NavWave2',
        // data: this.waveState.wave.inputs[0],
        no: 0,
      },
      {
        name: this.getName(
          this.waveState.wave.inputs[1].name,
          i18n.t('default-input2-name').toString(),
        ),
        component: 'InputView',
        is: 'NavWave2',
        // data: this.waveState.wave.inputs[1],
        no: 1,
      },
      {
        name: this.getName(
          this.waveState.wave.inputs[2].name,
          i18n.t('default-input-temperature-name').toString(),
        ),
        component: 'TemperatureView',
        is: 'NavWave2',
        // data: this.waveState.wave.inputs[2],
        no: 2,
      },
      {
        name: this.getName(
          this.waveState.wave.outputs[0].name,
          i18n.t('default-output1-name').toString(),
        ),
        component: 'OutputView',
        is: 'NavWave2',
        // data: this.waveState.wave.outputs[0],
        no: 0,
      },
      { is: 'NavWave2', spacer: true },
      { is: 'WaitingDownlink' },
      {
        name: i18n.t('auto_surveillance').toString(),
        component: 'AutoView',
        is: 'NavWave2',
        icon: 'far fa-eye',
      },
      {
        name: i18n.t('alarm-history').toString(),
        component: 'AlarmHistoryView',
        is: 'NavWave2',
        icon: 'fas fa-map-marker-alt',
      },
      {
        name: i18n.t('configuration').toString(),
        component: 'ConfigView',
        is: 'NavWave2',
        icon: 'fas fa-cog',
      },
    ];
  }

  mounted() {
    this.get(this._idDevice);
  }
}
