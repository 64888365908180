






































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { UserState, User, TYPE_ACCOUNT } from '@/store/user/types';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';
import Input from '@/components/Input.vue';
import CheckBox from '@/components/CheckBox.vue';
import AbstractPageComponent from './AbstractPageComponent.vue';
import SelectComponent from '@/components/SelectComponent.vue';
import RegisterFormForm from '@/components/RegisterFormForm.vue';
import { WaveState } from '../store/wave/types';
import i18n from '@/i18n';

const namespace: string = 'user';

@Component({
  components: {
    Alert,
    Input,
    CheckBox,
    SelectComponent,
    RegisterFormForm,
  },
})
export default class RegisterForm extends AbstractPageComponent {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @Mutation('alert/HIDE') private alertOff: () => void;
  @State('user') private userState?: UserState;
  @State('wave') private waveState?: WaveState;
  @Action('register', { namespace }) private register: (_: {
    user: User;
    code: string;
  }) => Promise<undefined>;

  public user: User = {
    accountType: TYPE_ACCOUNT.INDIVIDUAL,
    individualContact: {},
    technicalContact: {},
    company: {},
    administrativeContact: {},
  };

  private pendingResolve: any;
  private pendingReject: any;

  private onSubmit() {
    this.register({
      user: this.user,
      code: this.waveState!.deviceCode!.code,
    })
      .then(() => {
        this.pendingResolve();
      })
      .catch(() => {
        this.pendingReject();
      });
  }

  next(): Promise<any> {
    return new Promise((resolve, reject) => {
      // resolve();
      this.pendingReject = reject;
      this.pendingResolve = resolve;
      (this.$refs.registerForm as RegisterFormForm).submit();
    });
  }
}
