










import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import Cookie from '@/components/Cookie.vue';

@Component({
  components: {
    Loading,
    Cookie,
  },
  computed: {
    layout(): string {
      if (this.$route.meta != null && this.$route.meta.layout != null)
        return this.$route.meta.layout;
      else return 'default-layout';
    },
  },
})
export default class App extends Vue {}
