




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Graphic extends Vue {}
