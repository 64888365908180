










import { Component, Vue } from 'vue-property-decorator';
import SideBar from '@/components/SideBar.vue';
import NavBar from '@/components/NavBar.vue';
import { State, Mutation, Getter } from 'vuex-class';

@Component({
  components: {
    SideBar,
    NavBar,
  },
})
export default class ConnectedLayout extends Vue {
  @State('menuVisible') private menuVisible: boolean;
  @Mutation('closeMenu') private closeMenu: () => void;
}
