export enum TYPE_ACCOUNT {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export interface Contact {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  email?: string;
}

export interface Address {
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

export interface Company {
  name?: string;
  siren?: string;
  vat?: string;
}

export interface User {
  email?: string;
  confirmEmail?: string;
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  password?: string;
  devices?: Array<any>;
  company?: Company & Address;
  technicalContact?: Contact;
  administrativeContact?: Contact;
  individualContact?: Contact & Address;
  accountType?: TYPE_ACCOUNT;
}

export interface UserState {
  user?: User;
}
