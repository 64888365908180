import { GetterTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<UserState, RootState> = {
  userDataDevicesSorted: (state): any[] => {
    let userDataDevices: Array<any> = [];

    if (state.user != null && Array.isArray(state.user.devices)) {
      let deviceNames = state.user.devices.filter(
        (d) => typeof d.name === 'string' && d.name.length > 1,
      );

      let deviceMacs = state.user.devices.filter(
        (d) => deviceNames.indexOf(d) === -1,
      );

      deviceNames = deviceNames.sort((a, b) => a.name.localeCompare(b.name));
      deviceMacs = deviceMacs.sort((a, b) => a.mac.localeCompare(b.mac));

      userDataDevices = [...deviceNames, ...deviceMacs];
    }

    return userDataDevices;
  },
  userDataDevices: (state): any[] => {
    let userDataDevices: Array<any> = [];

    if (state.user != null && Array.isArray(state.user.devices)) {
      userDataDevices = state.user.devices;
    }

    return userDataDevices;
  },
};
