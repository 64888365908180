import store from '@/store/store';
import { Error } from '@/store/types';
import { MutationTree } from 'vuex';
import { AlarmDocument, AlarmState } from './types';

export const mutations: MutationTree<AlarmState> = {
  ALARM_SUCCESS: (state, payload: AlarmDocument[]) => {
    state.alarms = payload;
  },
  ALARM_SUCCESS_MORE: (state, payload: AlarmDocument[]) => {
    state.alarms = [...(state.alarms || []), ...payload];
  },
  ALARM_SUCCESS_NO_MORE: (state, payload: AlarmDocument[]) => {
    state.noMoreAlarm = true;
  },
  ALARM_ERROR: (state, payload: Error) => {
    state.alarms = [];
    store.commit('alert/SHOW', {
      prefix: 'ALARM_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
};
