import { Error, RootState } from '@/store/types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { WaveState, Wave, ReportDocument, Prediction } from './types';
import store from '@/store/store';

export const actions: ActionTree<WaveState, RootState> = {
  getGeolocation({ commit, state }): void {
    // Hide alert
    store.commit('alert/HIDE');

    // Create promise
    let promise = new Promise<Position>((resolve, reject) => {
      // Check if geolocation is available
      if (navigator != null && navigator.geolocation != null) {
        // Loading
        store.commit('loading/START');

        // Start geolocation
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (error) => {
            reject();
          },
          { timeout: 30 * 1000 },
        );
      } else {
        reject();
      }
    });

    // Promise resolver
    promise
      .then((position: Position) => {
        // Hide loading
        store.commit('loading/STOP');
        // Success mutation
        commit('WAVE_GEOLOCATION_SUCCESS', {
          lat: position.coords.latitude.toFixed(2),
          lng: position.coords.longitude.toFixed(2),
        });
      })
      .catch((error) => {
        // Hide loading
        store.commit('loading/STOP');
        // Error
        commit('WAVE_GEOLOCATION_ERROR');
      });
  },
  post({ commit, state }, waveCode: string): Promise<Wave> {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/v2/users/devices',
        method: 'PUT',
        data: { code: waveCode },
      }).then(
        (response: AxiosResponse) => {
          resolve(response.data as Wave);
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject(payload);
        },
      );
    });
  },
  testCode({ commit, state }, codeDevice: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/users/devicecode/${codeDevice}`,
        method: 'GET',
      }).then(
        (response: AxiosResponse) => {
          const payload: string = response && response.data;
          commit('CODE_WAVE_SUCCESS', payload);
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  getCoverage({ commit, state }, idDevice: string): void {
    axios({
      url: `/api/v2/devices/${idDevice}/coverage`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: Prediction = response && response.data;
        commit('WAVE_COVERAGE_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('WAVE_COVERAGE_ERROR', payload);
      },
    );
  },
  get({ commit, state }, idDevice: string): void {
    axios({
      url: `/api/v2/devices/${idDevice}`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: Wave = response && response.data;
        commit('WAVE_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('WAVE_ERROR', payload);
      },
    );
  },
  put({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}`,
        method: 'PUT',
        data: wave,
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          commit('WAVE_PUT_SUCCESS', payload);
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  delete({ commit }, _idDevice: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${_idDevice}`,
        method: 'DELETE',
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          commit('WAVE_DELETE_SUCCESS', payload);
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_DELETE_ERROR', payload);
          reject();
        },
      );
    });
  },
  deleteConfirmation({ commit }, deleteCode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/delete/${deleteCode}`,
        method: 'GET',
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          commit('WAVE_DELETION_SUCCESS', payload);
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_DELETION_ERROR', payload);
          reject();
        },
      );
    });
  },
  postReport({ commit, state }, value: any): Promise<void> {
    let report = {
      type: value.report.type,
      url: `${value.report.protocol}://${value.report.ip}:${value.report.port}`,
      encryptionType: value.report.encryptionType,
    };
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${value._idDevice}/reports`,
        method: 'POST',
        data: report,
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('REPORT_ERROR', payload);
          reject();
        },
      );
    });
  },
  putReport({ commit, state }, value: any): Promise<void> {
    value.reports.map((r: ReportDocument) => {
      if (
        typeof r.protocol === 'string' &&
        r.protocol.trim().length > 0 &&
        typeof r.ip === 'string' &&
        r.ip.trim().length > 0 &&
        typeof r.port === 'number' &&
        r.port > 0
      ) {
        r.url = `${r.protocol}://${r.ip}:${r.port}`;
      } else delete r.url;
    });
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${value._idDevice}/reports`,
        method: 'PUT',
        data: value.reports,
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('REPORT_ERROR', payload);
          reject();
        },
      );
    });
  },
  putPort({ commit, state }, value: any): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${value._id}/${value.type}/${value.port.no}`,
        method: 'PUT',
        data: value.port,
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('REPORT_ERROR', payload);
          reject();
        },
      );
    });
  },
  putAlarmCode({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}/alarmCode`,
        method: 'PUT',
        data: {
          startingAlarmCode: wave.startingAlarmCode,
          sigfoxOverDataAlarmCode: wave.sigfoxOverDataAlarmCode,
          lowerVoltageAlarmCode: wave.lowerVoltageAlarmCode,
          thresholdCrossingAlarmCode: wave.thresholdCrossingAlarmCode,
          supervisionAlarmCode: wave.supervisionAlarmCode,
          rssiAlarmCode: wave.rssiAlarmCode,
          sendInternalFaultsToCentralStation:
            wave.sendInternalFaultsToCentralStation,
        },
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  putAPN({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}/apn`,
        method: 'PUT',
        data: {
          gsm: wave.gsm,
        },
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          commit('PUT_APN_SUCCESS', {});
          // resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  putGSM({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}/gsm`,
        method: 'PUT',
        data: {
          gsm: wave.gsm,
        },
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  putDelAll({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}/delall`,
        method: 'PUT',
        data: {},
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  putVocalPhoneNumbers({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}/vocalPhoneNumbers`,
        method: 'PUT',
        data: {
          gsm: wave.gsm,
        },
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_ERROR', payload);
          reject();
        },
      );
    });
  },
  putOutput({ commit, state }, value: any): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${value._id}/outputs/${value.no}/value`,
        method: 'PUT',
        data: value,
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('REPORT_ERROR', payload);
          reject();
        },
      );
    });
  },
  reboot({ commit, state }, _idDevice: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${_idDevice}/reboot`,
        method: 'POST',
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('REBOOT_ERROR', payload);
          reject();
        },
      );
    });
  },
  reset({ commit, state }, _idDevice: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${_idDevice}/factoryreset`,
        method: 'POST',
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('RESET_ERROR', payload);
          reject();
        },
      );
    });
  },
  // postTest4G({ commit, state }, _idDevice: string): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       url: `/api/v2/devices/${_idDevice}/test4g`,
  //       method: 'POST',
  //     }).then(
  //       () => {
  //         resolve();
  //       },
  //       (error: AxiosError) => {
  //         const payload: Error = new Error(erforce4gror);
  //         commit('TEST4G_ERROR', payload);
  //         reject();
  //       },
  //     );
  //   });
  // },
  // putForce4G(
  //   { commit, state },
  //   data: { _idDevice: string; value: boolean },
  // ): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       url: `/api/v2/devices/${data._idDevice}/force4g`,
  //       method: 'PUT',
  //       data,
  //     }).then(
  //       () => {
  //         resolve();
  //       },
  //       (error: AxiosError) => {
  //         const payload: Error = new Error(error);
  //         commit('FORCE4G_ERROR', payload);
  //         reject();
  //       },
  //     );
  //   });
  // },
  putAutoSurvey({ commit, state }, wave: Wave): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${wave._id}/autosurvey`,
        method: 'PUT',
        data: wave.autosurvey,
      }).then(
        (response: AxiosResponse) => {
          const payload: Wave = response && response.data;
          commit('AUTO_SUCCESS', payload);
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('AUTO_ERROR', payload);
          reject();
        },
      );
    });
  },
  putNotif({ commit, state }, value: any): Promise<any> {
    return axios({
      url: `/api/v2/devices/${value._id}/${value.type}/${value.no}/notification`,
      method: 'PUT',
      data: value.notification,
    }).then(
      (response: AxiosResponse) => {},
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('NOTIF_ERROR', payload);
      },
    );
  },
  deleteDownlink({ commit }, _idDevice: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v2/devices/${_idDevice}/downlink`,
        method: 'DELETE',
      }).then(
        () => {
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('WAVE_DOWNLINK_DELETE_ERROR', payload);
          reject();
        },
      );
    });
  },
};
