




import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';

export default abstract class AbstractPageComponent extends Vue {
  abstract next(): Promise<any>;
}
