











import Alert from '@/components/Alert.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({
  components: {
    Alert,
  },
})
export default class Activation extends Vue {
  @Action('activation', { namespace: 'user' }) private activation: (
    code: String,
  ) => void;
  @Prop(String) private activationCode: string;

  mounted() {
    this.activation(this.activationCode);
  }

  onLogin() {
    this.$router.push('/');
  }
}
