
















import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { WaveState, Wave } from '@/store/wave/types';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';
import Input from '@/components/Input.vue';
import AbstractPageComponent from '@/components/AbstractPageComponent.vue';

@Component({
  components: {
    Alert,
    Input,
  },
})
export default class RegisterWave extends AbstractPageComponent {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @Mutation('alert/HIDE') private alertOff: () => void;
  @Action('get', { namespace: 'user' }) private get: () => void;
  @Action('testCode', { namespace: 'wave' }) private testCode: (
    code: string,
  ) => Promise<undefined>;

  public code: string = '';

  mounted() {
    this.alertOff();
  }

  next(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.code !== '') {
        this.testCode(this.code)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      } else {
        this.alert({
          prefix: 'INPUT_',
          error: 'EMPTY',
        });
      }
    });
  }
}
