export enum DeviceInputType {
  NO,
  NC,
  TEMPERATURE,
}

export enum DeviceOutputType {
  NORMAL,
  IMPULSE,
}

export enum DeviceOutputPassingState {
  NO,
  NC,
}

export enum ReportType {
  'CID-AIP' = 0,
  // 'CID-SIA' = 1,
  // 'CESA-SIA' = 2,
  // 'SIA-DC09' = 3,
  'CID-SIA' = 3,
}

export enum EncryptionType {
  'NONE' = 0,
  'AES-256' = 1,
}

enum DOWNLINK_DATA_BYTE1 {
  LOW_POWER_MODE = 1 << 0,
  DUTY_CYCLE = 1 << 1,
  TEST_MODE = 1 << 2,
  EMPTY3 = 1 << 3,
  REBOOT = 1 << 4,
  FACTORY_RESET = 1 << 5,
  EMPTY6 = 1 << 6,
  EMPTY7 = 1 << 7,
}

enum DOWNLINK_DATA_BYTE2 {
  IMPULSE_MODE = 1 << 0,
  NC = 1 << 1,
  SYNTHESIS_INTERNAL_FAULT = 1 << 2,
  EMPTY3 = 1 << 3,
  OUTPUT1_ACTIVE = 1 << 4,
  EMPTY5 = 1 << 5,
  EMPTY6 = 1 << 6,
  EMPTY7 = 1 << 7,
}

enum DOWNLINK_DATA_BYTE3 {
  INPUT1_NO = 1 << 0,
  INPUT1_TX_FRONT = 1 << 1,
  INPUT1_TIME_BASE = 1 << 2,
  EMPTY3 = 1 << 3,
  INPUT2_NO = 1 << 4,
  INPUT2_TX_FRONT = 1 << 5,
  INPUT2_TIME_BASE = 1 << 6,
  EMPTY7 = 1 << 7,
}

enum DOWNLINK_DATA_BYTE6 {
  THRESHOLD_UP_ALERT = 1 << 6,
  THRESHOLD_DOWN_ALERT = 1 << 7,
}

export interface DeviceAlarmCodeDocument {
  code?: number;
  zone?: number;
  group?: number;
}

export class DeviceGsmDocument {
  public apnName?: string;
  public apnUser?: string;
  public apnPassword?: string;
  public number?: string;
  public pollingPeriod?: number;
  public force4g?: boolean;
  public availableVOLTE?: boolean;
  public networkType?: number;
  public gsmRssi?: number;
  public operator?: string;
}

export interface DeviceNotificationDocument {
  centralStationSubscription?: boolean;
  emailSubscription?: boolean;
  thresholdValue?: number;
  thresholdUpAlert?: boolean;
  thresholdDownAlert?: boolean;
  message?: string;
  emails?: string[];
  code?: number;
  zone?: number;
  group?: number;
}

export interface DeviceInputDocument {
  no?: number;
  name?: string;
  value?: number;
  type?: DeviceInputType;
  timestamp?: number;
  calibrate?: number;
  restore?: boolean;
  timeBase?: number;
  txFront?: boolean;
  delay?: number;
  notification?: DeviceNotificationDocument;
}

export interface DeviceOutputDocument {
  no?: number;
  name?: string;
  timestamp?: number;
  value?: number;
  type?: DeviceOutputType;
  delay?: number;
  notification?: DeviceNotificationDocument;
  passingState?: DeviceOutputPassingState;
  synthesisInternalFault?: boolean;
}

export interface ReportDocument {
  no?: number;
  url: string;
  protocol: string;
  ip: string;
  port: number;
  type?: ReportType;
  encryptionType?: EncryptionType;
  encryptionKey?: string;
}

export interface DeviceAutosurveyGroupDocument {
  code?: number;
  name?: string;
}
export interface DeviceAutosurveyUserDocument {
  code?: number;
  name?: string;
}
export interface DeviceAutosurveyZoneDocument {
  code?: number;
  name?: string;
}
export interface DeviceAutosurveyNotificationDocument {
  code?: string;
  text?: string;
  filter?: string;
  emails?: string[];
}

export interface DeviceAutosurveyDocument {
  groups?: DeviceAutosurveyGroupDocument[];
  users?: DeviceAutosurveyUserDocument[];
  zones?: DeviceAutosurveyZoneDocument[];
  notifications?: DeviceAutosurveyNotificationDocument[];
}

export interface DownlinkDataPayload {
  lowPowerMode?: boolean;
  emulationMode?: boolean;
  autoProg?: boolean;
  messageFilter?: number;
  dutyCycle?: boolean;
  testMode?: boolean;
  reboot?: boolean;
  factoryReset?: boolean;
  // test4g?: boolean;
  force4g?: boolean;
  output1ImpulseMode?: number;
  output1PassingState?: number;
  output1SynthesisInternalFault?: boolean;
  output1Active?: number;
  input1No?: number;
  input1TxFront?: boolean;
  input1TimeBase?: number;
  input2No?: number;
  input2TxFront?: boolean;
  input2TimeBase?: number;
  input1Tempo?: number;
  input2Tempo?: number;
  thresholdValue?: number;
  thresholdUpAlert?: boolean;
  thresholdDownAlert?: boolean;
  serverTimeHours?: number;
  serverTimeMinutes?: number;
}

export interface DeviceDownlinkData {
  data?: string;
  payload?: DownlinkDataPayload;
  insertTime?: number;
  downloadTime?: number;
  nextDownloadTime?: number;
  active?: boolean;
}

export interface DeviceCode {
  code: string;
}

export class Prediction {
  'locationCovered': boolean;
  'margins': number[];
}

export interface Wave {
  _id?: string;
  sigfoxSerial?: string;
  deviceCode?: string;
  acct?: string;
  reports?: ReportDocument[];
  sigfoxPac?: string;
  activated?: boolean;
  m1?: string;
  m2?: string;
  m1Upgradable?: boolean;
  m2Upgradable?: boolean;
  mac?: string;
  name?: string;
  starting?: boolean;
  lifeTest?: boolean;
  lowPowerMode?: boolean;
  emulationMode?: boolean;
  autoProg?: boolean;
  messageFilter?: number;
  dutyCycle?: boolean;
  lat?: boolean;
  lng?: boolean;
  lowerVoltage?: boolean;
  sigfoxOverData?: boolean;
  inputs?: DeviceInputDocument[];
  outputs?: DeviceOutputDocument[];
  autosurvey?: DeviceAutosurveyDocument;
  hardwareVersion?: string;
  softwareVersion?: string;
  testMode?: boolean;
  gsm?: DeviceGsmDocument;
  downlinkData?: DeviceDownlinkData;
  startingAlarmCode?: DeviceAlarmCodeDocument;
  sigfoxOverDataAlarmCode?: DeviceAlarmCodeDocument;
  lowerVoltageAlarmCode?: DeviceAlarmCodeDocument;
  thresholdCrossingAlarmCode?: DeviceAlarmCodeDocument;
  supervisionAlarmCode?: DeviceAlarmCodeDocument;
  rssiAlarmCode?: DeviceAlarmCodeDocument;
  sendInternalFaultsToCentralStation?: boolean;
  // available4g?: boolean;
  // test4gProgress?: boolean;
  // test4g?: boolean;
  // force4g?: boolean;
}

export interface WaveState {
  downlinkPendingValues: Array<string>;
  wave?: Wave;
  prediction?: Prediction;
  deviceCode?: DeviceCode;
}
