











































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { WaveState, Wave } from '@/store/wave/types';
import { LogState, SimpleLog } from '@/store/log/types';
import LogMore from '@/components/LogMoreComponent.vue';
import CheckBox from '@/components/CheckBox.vue';

const namespace: string = 'log';

@Component({
  components: {
    LogMore,
    CheckBox,
  },
})
export default class AlarmHistory extends Vue {
  @State('wave') private waveState?: WaveState;
  @State('log') private logState?: LogState;
  @Getter('simpleLogs', { namespace })
  private simpleLogs?: Array<SimpleLog>;
  @Action('get', { namespace }) private get: (data: {
    idDevice: string;
    page: number;
    pageSize: number;
    verbose: boolean;
  }) => void;

  private page: number = 1;
  public verbose: boolean = false;
  private pageSize: number = 100;

  loadMore() {
    if (
      this.waveState != null &&
      this.waveState.wave != null &&
      this.waveState.wave._id != null
    ) {
      this.get({
        idDevice: this.waveState.wave._id,
        page: this.page++,
        pageSize: this.pageSize,
        verbose: this.verbose,
      });
    }
  }

  mounted() {
    this.initLoad();
  }

  initLoad() {
    this.page = 1;
    this.pageSize = 100;
    this.loadMore();
  }

  @Watch('waveState.wave._id')
  onChangeDevice(value: any) {
    this.initLoad();
  }
}
