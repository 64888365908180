import { Error, RootState } from '@/store/types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { UserState, User } from './types';

export const actions: ActionTree<UserState, RootState> = {
  get({ commit, state }): any {
    axios({
      url: `/api/v2/users`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: User = response && response.data;
        commit('USER_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('USER_ERROR', payload);
      },
    );
  },
  login({ commit, state }, user: User): any {
    axios({
      url: '/api/v2/users/login',
      method: 'POST',
      data: user,
    }).then(
      (response: AxiosResponse) => {
        const payload: User = response && response.data;
        commit('LOGIN_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('LOGIN_ERROR', payload);
      },
    );
  },
  logout({ commit, state }): any {
    axios({
      url: '/api/v2/users/logout',
      method: 'POST',
    }).then(
      (response: AxiosResponse) => {
        const payload: User = response && response.data;
        commit('LOGOUT_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('LOGOUT_ERROR', payload);
      },
    );
  },
  register({ commit, state }, { user, code }): any {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/v2/users/register',
        method: 'POST',
        data: { ...user, code },
      }).then(
        () => {
          commit('REGISTER_SUCCESS');
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('REGISTER_ERROR', payload);
          reject();
        },
      );
    });
  },
  activation({ commit, state }, uuid: String): any {
    axios({
      url: `/api/v2/users/activation/${uuid}`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: User = response && response.data;
        commit('ACTIVATION_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('ACTIVATION_ERROR', payload);
      },
    );
  },
  activationEmail({ commit, state }, uuid: String): any {
    axios({
      url: `/api/v2/users/email/${uuid}`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: User = response && response.data;
        commit('ACTIVATION_EMAIL_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('ACTIVATION_EMAIL_ERROR', payload);
      },
    );
  },
  deleteConfirmation({ commit, state }, uuid: String): any {
    axios({
      url: `/api/v2/users/delete/${uuid}`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: User = response && response.data;
        commit('USER_DELETED_SUCCESS', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('USER_DELETED_ERROR', payload);
      },
    );
  },
  lostPassword({ commit }, user: User): Promise<undefined> {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/v2/users/lostpassword',
        method: 'POST',
        data: user,
      }).then(
        () => {
          commit('LOST_PASSWORD_SUCCESS');
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('LOST_PASSWORD_ERROR', payload);
          reject();
        },
      );
    });
  },
  password({ commit }, user: User): Promise<undefined> {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/v2/users/password',
        method: 'PUT',
        data: user,
      }).then(
        () => {
          commit('CHANGE_PASSWORD_SUCCESS');
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('CHANGE_PASSWORD_ERROR', payload);
          reject();
        },
      );
    });
  },
  email({ commit }, user: User): Promise<undefined> {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/v2/users/email',
        method: 'PUT',
        data: user,
      }).then(
        () => {
          commit('CHANGE_EMAIL_SUCCESS');
          resolve();
        },
        (error: AxiosError) => {
          const payload: Error = new Error(error);
          commit('CHANGE_EMAIL_ERROR', payload);
          reject();
        },
      );
    });
  },
  delete({ commit }): any {
    axios({
      url: '/api/v2/users',
      method: 'DELETE',
    }).then(
      () => {
        commit('DELETE_ACCOUNT_SUCCESS');
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('DELETE_ACCOUNT_ERROR', payload);
      },
    );
  },
  update({ commit }, user: User): Promise<any> {
    return axios({
      url: '/api/v2/users',
      method: 'PUT',
      data: user,
    }).then(
      () => {
        commit('USER_UPDATE_SUCCESS');
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('USER_UPDATE_ERROR', payload);
      },
    );
  },
};
