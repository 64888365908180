import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import WavePage from '@/views/Wave.vue';
import Account from '@/views/Account.vue';
import Activation from '@/views/Activation.vue';
import UserDeletion from '@/views/UserDeletion.vue';
import DeviceDeletion from '@/views/DeviceDeletion.vue';
import ActivationEmail from '@/views/ActivationEmail.vue';
import LostPassword from '@/views/LostPassword.vue';
import AddWave from '@/views/AddWave.vue';
import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store';
import Session from './plugins/session';
import i18n from '@/i18n';

Vue.use(Router);

/**
 * Router
 */
const router: Router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/lost-password',
      name: 'lost-password',
      component: LostPassword,
    },
    {
      path: '/activation/:activationCode',
      name: 'activation',
      props: true,
      component: Activation,
    },
    {
      path: '/delete/:activationCode',
      name: 'user-deletion',
      props: true,
      component: UserDeletion,
    },
    {
      path: '/device/delete/:deleteCode',
      name: 'device-deletion',
      props: true,
      component: DeviceDeletion,
    },
    {
      path: '/email/:activationCode',
      name: 'email',
      props: true,
      component: ActivationEmail,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        isPrivate: true,
        layout: 'connected-layout',
        title: 'home',
      },
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        isPrivate: true,
        layout: 'connected-layout',
        title: 'account',
      },
    },
    {
      path: '/wave/:_idDevice',
      name: 'wave',
      component: WavePage,
      props: true,
      meta: {
        isPrivate: true,
        layout: 'connected-layout',
        title: 'wave-page',
      },
    },
    {
      path: '/wave/:_idDevice/advanced',
      name: 'wave',
      component: WavePage,
      props: true,
      meta: {
        isPrivate: true,
        layout: 'connected-layout',
        title: 'wave-page',
        advanced: true,
      },
    },
    {
      path: '/add-wave',
      name: 'add-wave',
      component: AddWave,
      meta: {
        isPrivate: true,
        layout: 'connected-layout',
        title: 'add-wave',
      },
    },
  ],
});

/**
 * Before each route
 */
router.beforeEach((to, from, next) => {
  // Session plugin
  const session: Session = Vue.$session;

  // Check if user authticated
  const isAuthenticated = session.isAuthenticated();

  store.commit('alert/HIDE');

  // Check meta private or go login page
  if (to.meta != null && to.meta.isPrivate && !isAuthenticated) {
    next('/login');
  }
  // Goto next route
  else {
    let canContinue = true;
    if (store.state.dataChangedCounter > 1) {
      canContinue = confirm(i18n.t('confirm_data_changed').toString());
    }

    if (canContinue) {
      store.commit('dataChangedReset');
      next();
    }
  }
});

export default router;
