

















































































































































































































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { UserState, User, TYPE_ACCOUNT } from '@/store/user/types';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Input from '@/components/Input.vue';
import CheckBox from '@/components/CheckBox.vue';
import AbstractPageComponent from './AbstractPageComponent.vue';
import SelectComponent from '@/components/SelectComponent.vue';
import { WaveState } from '../store/wave/types';
import i18n from '@/i18n';

const namespace: string = 'user';

@Component({
  components: {
    Input,
    CheckBox,
    SelectComponent,
  },
})
export default class RegisterFormForm extends Vue {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @State('user') private userState?: UserState;
  @State('wave') private waveState?: WaveState;
  @Prop(Object) private user: User;
  @Prop(String) private mode: String;

  public cgu: Number = 0;

  public TYPE_ACCOUNT = TYPE_ACCOUNT;

  private optionCountries: String[] = [''];

  beforeMount() {
    // Add countries localization
    i18n
      .t('countries')
      .toString()
      .split('|')
      .map((value, key) => {
        this.optionCountries.push(value);
      });
  }

  @Watch('user')
  private onUser(user: User) {
    user.individualContact = user.individualContact || {};
    user.technicalContact = user.technicalContact || {};
    user.company = user.company || {};
    user.administrativeContact = user.administrativeContact || {};
  }

  @Emit('submit')
  private onSubmit() {
    window.scrollTo(0, 0);
    if (this.mode === 'create') {
      if (this.user.password !== this.user.confirmPassword) {
        return this.alert({
          prefix: 'INPUT_PASSWORD_',
          error: 'NOT_SAME',
        });
      }

      if (this.user.email !== this.user.confirmEmail) {
        return this.alert({
          prefix: 'INPUT_EMAIL_',
          error: 'NOT_SAME',
        });
      }
    }
  }

  public submit() {
    (this.$refs.submitButton as HTMLButtonElement).click();
  }
}
