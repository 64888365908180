import { RootState } from '@/store/types';
import { GetterTree } from 'vuex';
import { LogState, LOG_KEY, SimpleLog, LogDocument } from './types';
import i18n from '@/i18n';

/**
 *
 *
 * @param {LogDocument} log
 * @return {*}  {boolean}
 */
function isCyclicTest(log: LogDocument): boolean {
  return (
    typeof log.data.data === 'string' &&
    log.data.data.match(/^80[\da-f]*601[\da-f]*$/g) != null
  );
}

export const getters: GetterTree<LogState, RootState> = {
  simpleLogs: (state): SimpleLog[] => {
    let simpleLogs: Array<SimpleLog> = [];

    let date = '';
    let information = '';

    for (const log of state.logs || []) {
      if (log.time != null) {
        date = `${new Date(log.time).toLocaleDateString()} ${new Date(
          log.time,
        ).toLocaleTimeString()}`;
        information = i18n.t(log.key as string, { ...log.data }).toString();

        // More informations
        let more: string | undefined = undefined;
        switch (log.key) {
          case LOG_KEY.NOTIFICATION_MAIL_SENT:
            more = log.data.mergedHtml;
            break;

          case LOG_KEY.DATA_RECEIVED_FROM_DEVICE:
          case LOG_KEY.ALARM_RECEIVED_FROM_DEVICE:
            more = log.data.data;
            break;

          case LOG_KEY.DATA_SENT_TO_DEVICE:
            more = log.data.downlinkData || JSON.stringify(log.data.polling);
            break;

          case LOG_KEY.ALARM_SENT_TO_TS:
            more = log.data.alarmString;
            break;

          case LOG_KEY.ALARM_NO_RECEIVED_FROM_TS:
          case LOG_KEY.ALARM_RECEIVED_FROM_TS_ACK:
          case LOG_KEY.ALARM_RECEIVED_FROM_TS_DUH:
            more = log.data.response;
            break;

          default:
            break;
        }

        if (!isCyclicTest(log))
          simpleLogs.push({
            date,
            information,
            data: log.data,
            key: log.key,
            more,
          });
      }
    }
    return simpleLogs;
  },
};
