import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { WaveState } from './types';
import { RootState } from '../types';

export const state: WaveState = {
  downlinkPendingValues: [],
  wave: undefined,
  prediction: undefined,
  deviceCode: undefined,
};

const namespaced: boolean = true;

export const wave: Module<WaveState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
