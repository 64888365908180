











import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';

@Component
export default class NavWave extends Vue {
  @State('alert') private alertState?: any;
  @Prop(Object) private value?: any;
  @Prop(Boolean) private selected?: boolean;
  @Prop(Boolean) private isWaveView?: boolean;
  @Prop(String) private configuration?: string;
  @Prop(String) private _id: string;
  @Action('get', { namespace: 'wave' }) private get: (_id: string) => void;

  @Emit('onData')
  public onData(value: any, configuration?: string) {}

  public onEvent() {
    this.get(this._id);
    this.alertState.visible = false;
    this.onData(this.value, this.configuration);
  }
}
