








import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { RootState } from '@/store/types';
import store from '@/store/store';
import Session from '@/plugins/session';

const namespace: string = 'cookie';

@Component
export default class Cookie extends Vue {
  private session: Session = Vue.$session;
  private cookieConsented: boolean = false;

  onAccept() {
    store.commit('cookieConsent');
    this.cookieConsented = true;
  }
}
