import Vue from 'vue';
import Vuex from 'vuex';
import { alert } from './alert';
import { modal } from './modal';
import { loading } from './loading';
import { alarm } from './alarm';
import { log } from './log';
import { wave } from './wave';
import { user } from './user';
import { RootState } from './types';
import session from '@/session';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    version: '1.0.0',
    menuVisible: false,
    dataChangedCounter: 0,
  },
  modules: {
    modal,
    wave,
    log,
    alarm,
    user,
    alert,
    loading,
  },
  mutations: {
    toggleMenu: (state) => {
      state.menuVisible = !state.menuVisible;
    },
    closeMenu: (state) => {
      state.menuVisible = false;
    },
    openMenu: (state) => {
      state.menuVisible = true;
    },
    dataChanged: (state) => {
      state.dataChangedCounter++;
    },
    dataChangedReset: (state) => {
      state.dataChangedCounter = 0;
    },
    cookieConsent: (state) => {
      session.cookieConsent(true);
    },
  },
  actions: {},
});
