


















import { Component, Prop, Vue, Model, Emit } from 'vue-property-decorator';

@Component
export default class SwitchBox extends Vue {
  @Prop([Boolean, Number]) private value: Boolean | Number;
  @Prop(String) private label: String;

  @Emit('input')
  onEvent() {
    return !this.value;
  }
}
