
































































































import Alert from '@/components/Alert.vue';
import CheckBox from '@/components/CheckBox.vue';
import DropDown from '@/components/Dropdown.vue';
import Input from '@/components/Input.vue';
import SelectComponent from '@/components/SelectComponent.vue';
import SwitchBox from '@/components/SwitchBox.vue';
import ConfigAdvancedWave from '@/components/wave/ConfigAdvancedWave.vue';
import ConfigAlarmCode from '@/components/wave/ConfigAlarmCode.vue';
import i18n from '@/i18n';
import { IMessage } from '@/store/alert/types';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    Alert,
    CheckBox,
    DropDown,
    SwitchBox,
    ConfigAdvancedWave,
    SelectComponent,
    ConfigAlarmCode,
  },
})
export default class ConfigGsm extends Vue {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @State('wave') public waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('putAPN', { namespace }) private putAPN: (
    value: any,
  ) => Promise<undefined>;
  @Action('putGSM', { namespace }) private putGSM: (
    value: any,
  ) => Promise<undefined>;
  @Getter('isDownlinkPendingValue', { namespace })
  private isDownlinkPendingValue: boolean;

  public pollingPeriodType: Array<any> = [
    { text: 'polling_1', value: 1 },
    { text: 'polling_2', value: 2 },
    { text: 'polling_5', value: 5 },
    { text: 'polling_10', value: 10 },
  ];

  onPut() {
    this.putAPN(this.waveState.wave)
      .then(() => {
        this.getWave(this.waveState.wave._id);
      })
      .catch((error) => {});
  }

  onPutGSM() {
    this.putGSM(this.waveState.wave)
      .then(() => {
        this.getWave(this.waveState.wave._id);
      })
      .catch((error) => {});
  }

  get networkType(): string {
    return i18n
      .t(
        `gsmNetworkType_${
          this.waveState.wave.gsm.networkType > -1
            ? this.waveState.wave.gsm.networkType
            : ''
        }`,
      )
      .toString();
  }
}
