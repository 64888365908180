






import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import Tutorial from '@/components/Tutorial.vue';
import AddWavegateway from '@/components/AddWavegateway.vue';
import { Wave } from '@/store/wave/types';
import AbstractPageComponent from '../components/AbstractPageComponent.vue';

@Component({
  components: {
    Tutorial,
    AddWavegateway,
  },
})
export default class AddWave extends Vue {
  public data: Array<typeof AbstractPageComponent> = [AddWavegateway];

  private onEnd(data: Wave) {
    if (data != null && data._id != null)
      this.$router.push(`/wave/${data._id}`);
  }
}
