













































































































































































































import Alert from '@/components/Alert.vue';
import CheckBox from '@/components/CheckBox.vue';
import DropDown from '@/components/Dropdown.vue';
import Input from '@/components/Input.vue';
import SelectComponent from '@/components/SelectComponent.vue';
import SwitchBox from '@/components/SwitchBox.vue';
import ConfigAdvancedWave from '@/components/wave/ConfigAdvancedWave.vue';
import ConfigAlarmCode from '@/components/wave/ConfigAlarmCode.vue';
import ConfigGsm from '@/components/wave/ConfigGsm.vue';
import ConfigVocalPhoneNumber from '@/components/wave/ConfigVocalPhoneNumber.vue';
import i18n from '@/i18n';
import store from '@/store/store';
import { Wave } from '@/store/wave/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    Alert,
    CheckBox,
    DropDown,
    ConfigGsm,
    ConfigVocalPhoneNumber,
    SwitchBox,
    ConfigAdvancedWave,
    ConfigAlarmCode,
    SelectComponent,
  },
})
export default class ConfigWave extends Vue {
  @State('wave') private waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('getGeolocation', { namespace }) private getGeolocation: () => void;
  @Action('getCoverage', { namespace }) private getCoverage: (
    _idDevice: string,
  ) => void;
  @Action('getAlarm', { namespace }) private getAlarm: (_id: string) => void;
  @Action('delete', { namespace }) private delete: (
    _id: string,
  ) => Promise<undefined>;
  @Action('reboot', { namespace }) private reboot: (
    _id: string,
  ) => Promise<undefined>;
  @Action('reset', { namespace }) private reset: (
    _id: string,
  ) => Promise<undefined>;
  @Action('put', { namespace }) private put: (wave: Wave) => Promise<undefined>;
  @Action('get', { namespace: 'user' }) private get: () => Promise<any>;

  @Getter('isDownlinkPendingValue', { namespace })
  private isDownlinkPendingValue: boolean;

  @Getter('isPendingReboot', { namespace })
  private isPendingReboot: boolean;
  @Getter('isPendingFactoryReset', { namespace })
  private isPendingFactoryReset: boolean;

  private timeoutPrediction: number = 0;

  private optionsmessageFilter: Array<{ text: string; value: Number }> = [
    { text: 'filter-message-0', value: 0 },
    { text: 'filter-message-1', value: 1 },
    { text: 'filter-message-2', value: 2 },
    { text: 'filter-message-3', value: 3 },
  ];

  @Watch('waveState.prediction', { deep: true })
  private onPrediction(value: any) {
    clearTimeout(this.timeoutPrediction);
    this.timeoutPrediction = setTimeout(() => {
      store.commit('wave/WAVE_COVERAGE_CLEAR');
    }, 5000);
  }

  private advanced(): boolean {
    if (this.$route.meta != null) return this.$route.meta.advanced === true;
    else return false;
  }

  private onDelete() {
    let Conf = confirm(i18n.t('confirm_delete_wavegateway').toString());
    if (Conf === true) {
      if (this.waveState.wave._id) {
        this.delete(this.waveState.wave._id);
      }
    }
  }

  private onReboot() {
    let idWave = this.waveState.wave._id;
    let Conf = confirm(i18n.t('confirm_restart_wavegateway').toString());
    if (Conf === true) {
      if (this.waveState.wave._id) {
        this.reboot(this.waveState.wave._id)
          .then(() => {
            this.get();
            this.getWave(idWave);
          })
          .catch(() => {});
      }
    }
  }

  private onReset() {
    let idWave = this.waveState.wave._id;
    let Conf = confirm(i18n.t('confirm_factory_wavegateway').toString());
    if (Conf === true) {
      if (this.waveState.wave._id) {
        this.reset(this.waveState.wave._id)
          .then(() => {
            this.get();
            this.getWave(idWave);
          })
          .catch(() => {});
      }
    }
  }

  private onChange() {
    let idWave = this.waveState.wave._id;
    if (this.waveState.wave) {
      this.put(this.waveState.wave)
        .then(async (data) => {
          await this.get();
          await this.getWave(idWave);
        })
        .catch(() => {});
    }
  }
}
