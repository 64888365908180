



























import Alert from '@/components/Alert.vue';
import SelectControl from '@/components/SelectControl.vue';
import SwitchBox from '@/components/SwitchBox.vue';
import { IMessage } from '@/store/alert/types';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import i18n from '@/i18n';

const namespace: string = 'wave';

@Component({
  components: {
    Alert,
    SelectControl,
    SwitchBox,
  },
})
export default class ConfigVocalPhoneNumber extends Vue {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @State('wave') private waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('putVocalPhoneNumbers', { namespace }) private putVocalPhoneNumbers: (
    value: any,
  ) => Promise<undefined>;
  @Action('putDelAll', { namespace }) private putDelAll: (
    value: any,
  ) => Promise<undefined>;

  onDelAll() {
    let conf = confirm(i18n.t('confirm_delall').toString());
    if (conf === true) {
      this.putDelAll(this.waveState.wave).catch((error) => {});
    }
  }

  onPutGSM() {
    this.putVocalPhoneNumbers(this.waveState.wave)
      .then(() => {
        this.getWave(this.waveState.wave._id);
      })
      .catch((error) => {});
  }
}
