












import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';
import { WaveState } from '../../store/wave/types';

const namespace: string = 'wave';

@Component
export default class NavWave2 extends Vue {
  @Prop(String) private icon?: string;
  @Prop(String) private name?: string;
  @Prop(String) private component?: string;
  @Prop(Object) private data?: any;
  @Prop(Boolean) private selected?: boolean;
  @Prop(Boolean) private spacer?: boolean;

  @State('wave') private waveState?: WaveState;
  @Action('get', { namespace }) private get: (idDevice: string) => void;

  @Emit('onClick')
  public onClick(name: string, component: string, data: any) {
    // if (this.waveState && this.waveState.wave && this.waveState.wave._id)
    //   this.get(this.waveState.wave._id);
  }
}
