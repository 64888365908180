















import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';

@Component
export default class SelectComponent extends Vue {
  @Prop(String) private label: String;
  @Prop(String) private type: String;
  @Prop(Boolean) private disabled: boolean;
  @Prop(Array) private options: Array<
    { text: string; value?: string | number } | string
  >;
  @Prop([String, Number]) private value?: String | Number;

  onInput(event: Event) {
    if (event.target != null) {
      let target = event.target as HTMLInputElement;
      if (this.type === 'number') {
        this.$emit('input', Number(target.value));
      } else {
        this.$emit('input', target.value);
      }
    }
  }
}
