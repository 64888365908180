




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Notification extends Vue {
  @Prop(Object) private data: any;
}
