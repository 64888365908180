import store from '@/store/store';
import { Error } from '@/store/types';
import { MutationTree } from 'vuex';
import {
  DeviceCode,
  EncryptionType,
  Prediction,
  ReportType,
  Wave,
  WaveState,
} from './types';

export const mutations: MutationTree<WaveState> = {
  WAVE_SUCCESS: (state, payload: Wave) => {
    // Clear state
    state.prediction = undefined;
    state.downlinkPendingValues = [];

    if (payload && payload.reports) {
      payload.reports.map((report, key) => {
        let regex = /^(?<protocol>tcp|udp):\/\/(?<ip>[^:]*):(?<port>\d*)$/.exec(
          report.url,
        );
        if (regex && regex.groups) {
          let { protocol, ip, port } = regex.groups;
          report.protocol = protocol;
          report.ip = ip;
          report.port = parseInt(port);
        } else {
          report.protocol = 'tcp';
          report.type = ReportType['CID-AIP'];
          report.encryptionType = EncryptionType.NONE;
        }

        if (report.no == null) {
          report.no = key + 1;
        }
      });
    }

    if (payload && Array.isArray(payload.inputs)) {
      payload.inputs.map((input) => {
        if (input.type != null) input.type = Number(input.type);
        if (input.timeBase != null) input.timeBase = Number(input.timeBase);
        if (input.delay != null) input.delay = Number(input.delay);
      });
    }

    if (payload && Array.isArray(payload.outputs)) {
      payload.outputs.map((output) => {
        if (output.type != null) output.type = Number(output.type);
        if (output.passingState != null)
          output.passingState = Number(output.passingState);
        if (output.notification == null) {
          output.notification = {
            emailSubscription: false,
            emails: [],
          };
        }
      });
    }

    if (payload.autosurvey == null)
      payload.autosurvey = {
        groups: [],
        notifications: [],
        users: [],
        zones: [],
      };

    if (payload.gsm == null) payload.gsm = {};
    if (payload.emulationMode == null) payload.emulationMode = false;
    if (payload.autoProg == null) payload.autoProg = true;

    if (payload.lowerVoltageAlarmCode == null)
      payload.lowerVoltageAlarmCode = {};
    if (payload.rssiAlarmCode == null) payload.rssiAlarmCode = {};
    if (payload.sigfoxOverDataAlarmCode == null)
      payload.sigfoxOverDataAlarmCode = {};
    if (payload.startingAlarmCode == null) payload.startingAlarmCode = {};
    if (payload.supervisionAlarmCode == null) payload.supervisionAlarmCode = {};
    if (payload.thresholdCrossingAlarmCode == null)
      payload.thresholdCrossingAlarmCode = {};

    // (payload.inputs || []).map((input) => {
    //   if (input.name == null) input.name = `${i18n.t('input')} ${input.no}`;
    //   if (input.type == null) input.type = DeviceInputType.NC;
    //   if (input.txFront == null) input.txFront = false;
    //   if (input.timeBase == null) input.timeBase = 0;
    //   if (input.notification == null) {
    //     input.restore = false;
    //     input.notification = {
    //       centralStationSubscription: false,
    //       emailSubscription: false,
    //       emails: [],
    //       thresholdValue: 0,
    //       thresholdUpAlert: false,
    //       thresholdDownAlert: false,
    //     };
    //   }
    // });

    // (payload.outputs || []).map((output) => {
    //   if (output.name == null) output.name = `${i18n.t('output')} ${output.no}`;
    //   if (output.type == null) output.type = DeviceOutputType.IMPULSE;
    //   if (output.synthesisInternalFault == null)
    //     output.synthesisInternalFault = false;
    //   if (output.passingState == null)
    //     output.passingState = DeviceOutputPassingState.NC;
    //   if (output.notification == null) {
    //     output.notification = {
    //       emailSubscription: false,
    //       emails: [],
    //     };
    //   }
    // });

    // if (payload.autosurvey == null)
    //   payload.autosurvey = {
    //     groups: [],
    //     notifications: [],
    //     users: [],
    //     zones: [],
    //   };

    // if (!Array.isArray(payload.autosurvey.groups))
    //   payload.autosurvey.groups = [];
    // if (!Array.isArray(payload.autosurvey.notifications))
    //   payload.autosurvey.notifications = [
    //     { code: '', emails: [], filter: '', text: '' },
    //   ];
    // if (!Array.isArray(payload.autosurvey.users)) payload.autosurvey.users = [];
    // if (!Array.isArray(payload.autosurvey.zones)) payload.autosurvey.zones = [];

    // if (payload.lowPowerMode == null) payload.lowPowerMode = false;
    // if (payload.dutyCycle == null) payload.dutyCycle = false;
    // if (payload.testMode == null) payload.testMode = false;

    // if (payload.testMode == null) payload.testMode = false;

    // if (payload.startingAlarmCode == null) payload.startingAlarmCode = {};
    // if (payload.sigfoxOverDataAlarmCode == null)
    //   payload.sigfoxOverDataAlarmCode = {};
    // if (payload.lowerVoltageAlarmCode == null)
    //   payload.lowerVoltageAlarmCode = {};
    // if (payload.thresholdCrossingAlarmCode == null)
    //   payload.thresholdCrossingAlarmCode = {};
    // if (payload.supervisionAlarmCode == null) payload.supervisionAlarmCode = {};
    // if (payload.rssiAlarmCode == null) payload.rssiAlarmCode = {};

    if (payload.messageFilter == null) payload.messageFilter = 0;

    if (
      payload.downlinkData != null &&
      payload.downlinkData.active === true &&
      payload.downlinkData.payload != null
    ) {
      // Pending values
      if (payload.lowPowerMode != payload.downlinkData.payload.lowPowerMode) {
        state.downlinkPendingValues.push('lowPowerMode');
        payload.lowPowerMode = payload.downlinkData.payload.lowPowerMode;
      }

      if (payload.emulationMode != payload.downlinkData.payload.emulationMode) {
        state.downlinkPendingValues.push('emulationMode');
        payload.emulationMode = payload.downlinkData.payload.emulationMode;
      }

      if (payload.autoProg != payload.downlinkData.payload.autoProg) {
        state.downlinkPendingValues.push('autoProg');
        payload.autoProg = payload.downlinkData.payload.autoProg;
      }

      // if (payload.downlinkData.payload.test4g) {
      //   state.downlinkPendingValues.push('test4g');
      // }

      // if (payload.force4g != payload.downlinkData.payload.force4g) {
      //   state.downlinkPendingValues.push('force4g');
      //   payload.force4g = payload.downlinkData.payload.force4g;
      // }

      if (
        payload.messageFilter != payload.downlinkData.payload.messageFilter &&
        payload.downlinkData.payload.messageFilter != null
      ) {
        state.downlinkPendingValues.push('messageFilter');
        payload.messageFilter = payload.downlinkData.payload.messageFilter;
      }

      if (payload.dutyCycle != payload.downlinkData.payload.dutyCycle) {
        state.downlinkPendingValues.push('dutyCycle');
        payload.dutyCycle = payload.downlinkData.payload.dutyCycle;
      }

      if (payload.testMode != payload.downlinkData.payload.testMode) {
        state.downlinkPendingValues.push('testMode');
        payload.testMode = payload.downlinkData.payload.testMode;
      }

      if (
        payload.inputs![2].notification!.thresholdDownAlert !=
        payload.downlinkData.payload.thresholdDownAlert
      ) {
        state.downlinkPendingValues.push('thresholdDownAlert');
        payload.inputs![2].notification!.thresholdDownAlert =
          payload.downlinkData.payload.thresholdDownAlert;
      }

      if (
        payload.inputs![2].notification!.thresholdUpAlert !=
        payload.downlinkData.payload.thresholdUpAlert
      ) {
        state.downlinkPendingValues.push('thresholdUpAlert');
        payload.inputs![2].notification!.thresholdUpAlert =
          payload.downlinkData.payload.thresholdUpAlert;
      }

      if (
        payload.inputs![2].notification!.thresholdValue !=
        payload.downlinkData.payload.thresholdValue
      ) {
        state.downlinkPendingValues.push('thresholdValue');
        payload.inputs![2].notification!.thresholdValue =
          payload.downlinkData.payload.thresholdValue;
      }

      // Input 1
      if (payload.inputs![0].type != payload.downlinkData.payload.input1No) {
        state.downlinkPendingValues.push('input1No');
        payload.inputs![0].type = payload.downlinkData.payload.input1No;
      }

      if (
        payload.inputs![0].delay != payload.downlinkData.payload.input1Tempo
      ) {
        state.downlinkPendingValues.push('input1Tempo');
        payload.inputs![0].delay = payload.downlinkData.payload.input1Tempo;
      }

      if (
        payload.inputs![0].timeBase !=
        payload.downlinkData.payload.input1TimeBase
      ) {
        state.downlinkPendingValues.push('input1TimeBase');
        payload.inputs![0].timeBase =
          payload.downlinkData.payload.input1TimeBase;
      }

      if (
        payload.inputs![0].txFront != payload.downlinkData.payload.input1TxFront
      ) {
        state.downlinkPendingValues.push('input1TxFront');
        payload.inputs![0].txFront = payload.downlinkData.payload.input1TxFront;
      }

      // Input 2
      if (payload.inputs![1].type != payload.downlinkData.payload.input2No) {
        state.downlinkPendingValues.push('input2No');
        payload.inputs![1].type = payload.downlinkData.payload.input2No;
      }

      if (
        payload.inputs![1].delay != payload.downlinkData.payload.input2Tempo
      ) {
        state.downlinkPendingValues.push('input2Tempo');
        payload.inputs![1].delay = payload.downlinkData.payload.input2Tempo;
      }

      if (
        payload.inputs![1].timeBase !=
        payload.downlinkData.payload.input2TimeBase
      ) {
        state.downlinkPendingValues.push('input2TimeBase');
        payload.inputs![1].timeBase =
          payload.downlinkData.payload.input2TimeBase;
      }

      if (
        payload.inputs![1].txFront != payload.downlinkData.payload.input2TxFront
      ) {
        state.downlinkPendingValues.push('input2TxFront');
        payload.inputs![1].txFront = payload.downlinkData.payload.input2TxFront;
      }

      // Output 1
      if (
        payload.outputs![0].value != payload.downlinkData.payload.output1Active
      ) {
        state.downlinkPendingValues.push('output1Active');
        payload.outputs![0].value = payload.downlinkData.payload.output1Active;
      }

      if (
        payload.outputs![0].type !=
        payload.downlinkData.payload.output1ImpulseMode
      ) {
        state.downlinkPendingValues.push('output1ImpulseMode');
        payload.outputs![0].type =
          payload.downlinkData.payload.output1ImpulseMode;
      }

      if (
        payload.outputs![0].passingState !=
        payload.downlinkData.payload.output1PassingState
      ) {
        state.downlinkPendingValues.push('output1PassingState');
        payload.outputs![0].passingState =
          payload.downlinkData.payload.output1PassingState;
      }

      if (
        payload.outputs![0].synthesisInternalFault !=
        payload.downlinkData.payload.output1SynthesisInternalFault
      ) {
        state.downlinkPendingValues.push('output1SynthesisInternalFault');
        payload.outputs![0].synthesisInternalFault =
          payload.downlinkData.payload.output1SynthesisInternalFault;
      }
    }

    state.wave = payload;
    store.commit('dataChangedReset');
  },
  PUT_APN_SUCCESS: (state) => {
    store.commit('alert/SHOW', {
      prefix: 'PUT_APN_SUCCESS_',
      success: '200',
    });
  },
  WAVE_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  WAVE_COVERAGE_SUCCESS: (state, payload: Prediction) => {
    state.prediction = payload;
  },
  WAVE_COVERAGE_CLEAR: (state, payload: Prediction) => {
    state.prediction = undefined;
  },
  WAVE_COVERAGE_ERROR: (state) => {
    state.prediction = undefined;
    store.commit('alert/SHOW', {
      error: 'WAVE_COVERAGE_ERROR',
    });
  },
  WAVE_GEOLOCATION_SUCCESS: (state, payload: any) => {
    const { lat, lng } = payload;
    if (state.wave != null) {
      state.wave.lat = lat;
      state.wave.lng = lng;
    }
  },
  WAVE_GEOLOCATION_ERROR: (state) => {
    store.commit('alert/SHOW', {
      error: 'WAVE_GEOLOCATION_ERROR',
    });
  },
  CODE_WAVE_SUCCESS: (state, payload: DeviceCode) => {
    state.deviceCode = payload;
  },
  AUTO_SUCCESS: (state, payload: Wave) => {},
  AUTO_ERROR: (state, payload: Error) => {},
  CODE_WAVE_ERROR: (state, payload: Error) => {
    state.wave = undefined;
    store.commit('alert/SHOW', {
      prefix: 'WAVE_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  REPORT_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'REPORT_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  WAVE_DOWNLINK_DELETE_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_DOWNLINK_DELETE_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  REBOOT_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'REBOOT_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  RESET_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'RESET_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  WAVE_PUT_SUCCESS: (state) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_PUT_SUCCESS_',
      success: '200',
    });
  },
  WAVE_DELETE_SUCCESS: (state) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_DELETE_SUCCESS_',
      success: '200',
    });
  },
  WAVE_DELETE_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_DELETE_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  WAVE_DELETION_SUCCESS: (state) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_DELETION_SUCCESS_',
      success: '200',
    });
  },
  WAVE_DELETION_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'WAVE_DELETION_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
};
