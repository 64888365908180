




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Historic extends Vue {
  @Prop(Object) private data: any;
}
