













import { Component, Prop, Vue, Model, Emit } from 'vue-property-decorator';

@Component
export default class CheckBox extends Vue {
  @Prop(String) private name: String;
  @Prop(Boolean) private required: boolean;
  @Prop(Number) private value: Number;
  @Prop(String) private type: String;
  @Prop(Boolean) private isReadOnly: Boolean;

  @Emit()
  input(value: Number) {
    if (this.isReadOnly) {
      return value;
    } else {
      if (value === 1) {
        return 0;
      } else {
        return 1;
      }
    }
  }
}
