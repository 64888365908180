

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MapWave extends Vue {
  @Prop(Object) private data: any;
  private isCircle: Boolean = false;
  private mapStartLocation: any = {
    lat: this.data.location.position.coordinates[1],
    lng: this.data.location.position.coordinates[0],
  };
}
