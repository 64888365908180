


















































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Mutation, Getter } from 'vuex-class';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import CheckBox from '@/components/CheckBox.vue';
import Dropdown from '@/components/Dropdown.vue';
import Input from '@/components/Input.vue';
import BoxReport from '@/components/wave/BoxReport.vue';
import SelectControl from '@/components/SelectControl.vue';
import SelectComponent from '@/components/SelectComponent.vue';
import SwitchBox from '@/components/SwitchBox.vue';
import {
  WaveState,
  Wave,
  ReportDocument,
  DeviceOutputDocument,
} from '@/store/wave/types';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    CheckBox,
    Dropdown,
    BoxReport,
    SelectControl,
    SelectComponent,
    SwitchBox,
  },
})
export default class ConfigurationOutput extends Vue {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @State('wave') private waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('putOutput', { namespace }) private putOutputValue: (
    value: any,
  ) => Promise<undefined>;
  @Action('putPort', { namespace }) private put: (
    value: any,
  ) => Promise<undefined>;
  @Action('putNotif', { namespace }) private putNotif: (
    value: any,
  ) => Promise<undefined>;
  @Prop(Object) private data: DeviceOutputDocument;

  @Getter('isDownlinkPendingValue', { namespace })
  private isDownlinkPendingValue: boolean;

  private arrayTypeOutput: Array<any> = [
    { text: 'normal', value: 0 },
    { text: 'impulse', value: 1 },
  ];

  private arrayPassingStateOutput: Array<any> = [
    { text: 'normally_open', value: 0 },
    { text: 'normally_close', value: 1 },
  ];

  onChangeOutputValue() {
    let value = {
      _id: this.waveState.wave._id,
      no: this.data.no,
      value: this.data.value ? 1 : 0,
    };
    this.putOutputValue(value)
      .then(() => {
        this.getWave(value._id);
      })
      .catch((error) => {});
  }

  onPutNotif() {
    let value = {
      _id: this.waveState.wave._id,
      type: 'outputs',
      notification: this.data.notification,
      no: this.data.no,
    };
    this.putNotif(value)
      .then(() => {
        this.getWave(value._id);
      })
      .catch((error) => {});
  }

  onPut() {
    let port = {};

    port = {
      name: this.data.name,
      no: this.data.no,
      type: Number(this.data.type),
      passingState: Number(this.data.passingState),
      synthesisInternalFault: this.data.synthesisInternalFault,
    };

    let value = {
      _id: this.waveState.wave._id,
      type: 'outputs',
      port: port,
    };
    if (this.data.name !== '') {
      this.put(value)
        .then(() => {
          this.getWave(value._id);
        })
        .catch((error) => {});
    } else {
      this.alert({
        prefix: 'INPUT_',
        error: 'EMPTY',
      });
    }
  }
}
