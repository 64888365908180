











import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';

@Component({
  components: {
    Alert,
  },
})
export default class Activation extends Vue {
  @Action('deleteConfirmation', { namespace: 'wave' })
  private deleteConfirmation: (code: String) => void;
  @Prop(String) private deleteCode: string;

  mounted() {
    this.deleteConfirmation(this.deleteCode);
  }

  onLogin() {
    this.$router.push('/');
  }
}
