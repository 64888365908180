import { render, staticRenderFns } from "./BoxReport.vue?vue&type=template&id=95fb1d02&scoped=true&"
import script from "./BoxReport.vue?vue&type=script&lang=ts&"
export * from "./BoxReport.vue?vue&type=script&lang=ts&"
import style0 from "./BoxReport.vue?vue&type=style&index=0&id=95fb1d02&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95fb1d02",
  null
  
)

export default component.exports