




























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';
import CheckBox from '@/components/CheckBox.vue';
import Dropdown from '@/components/Dropdown.vue';
import SelectComponent from '@/components/SelectComponent.vue';
import Input from '@/components/Input.vue';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    CheckBox,
    Dropdown,
    SelectComponent,
  },
})
export default class BoxReport extends Vue {
  @Prop(Object) private report: any;

  private arrayType: Array<any> = [
    { text: 'CID-AIP', value: 0 },
    { text: 'CID-SIA', value: 3 },
  ];
  private arrayCrypt: Array<any> = [
    { text: 'aucun', value: 0 },
    { text: 'AES256', value: 1 },
  ];
  private arrayProtocol: Array<string> = ['udp', 'tcp'];
}
