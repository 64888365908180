import { Error, RootState } from '@/store/types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ActionTree } from 'vuex';
import { AlarmState, AlarmDocument } from './types';

export const actions: ActionTree<AlarmState, RootState> = {
  get(
    { commit, state },
    data: {
      idDevice: string;
      page: number;
      pageSize: number;
    },
  ): void {
    axios({
      url: `/api/v2/devices/${data.idDevice}/alarms?page=${data.page}&pageSize=${data.pageSize}`,
      method: 'GET',
    }).then(
      (response: AxiosResponse) => {
        const payload: AlarmDocument[] = response && response.data;
        if (data.page > 1) commit('ALARM_SUCCESS_MORE', payload);
        else commit('ALARM_SUCCESS', payload);

        if (payload.length < data.pageSize)
          commit('ALARM_SUCCESS_NO_MORE', payload);
      },
      (error: AxiosError) => {
        const payload: Error = new Error(error);
        commit('ALARM_ERROR', payload);
      },
    );
  },
};
