export interface SigfoxPayloadDocument {
  device?: string;
  time?: Date;
  duplicate?: string;
  snr?: string;
  station?: string;
  data?: string;
  avgSnr?: string;
  lat?: string;
  lng?: string;
  rssi?: string;
  seqNumber?: string;
  ack?: boolean;
  longPolling?: string;
}

export interface AlarmAttempts {
  url: string;
  response: string;
  time: number;
}

export interface SimpleAlarm {
  date: string;
  information: string;
}

export enum ALARM_TYPE {
  TS = 'TS',
  MAIL = 'MAIL',
}

export interface AlarmMail {
  to?: string;
  bcc?: string[];
  content?: string;
}

export interface AlarmDocument {
  data?: string;
  response?: string;
  time?: number;
  type?: ALARM_TYPE;
  mail?: AlarmMail;
  sigfoxPayload?: SigfoxPayloadDocument;
  attempts?: AlarmAttempts[];
}

export interface AlarmState {
  alarms?: AlarmDocument[];
  noMoreAlarm: boolean;
}
