




import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import Tutorial from '@/components/Tutorial.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import RegisterWave from '@/components/RegisterWave.vue';
import RegisterSuccess from '@/components/RegisterSuccess.vue';
import AbstractPageComponent from '../components/AbstractPageComponent.vue';

@Component({
  components: {
    Tutorial,
    RegisterForm,
    RegisterWave,
  },
})
export default class Register extends Vue {
  public data: Array<typeof AbstractPageComponent> = [
    RegisterWave,
    RegisterForm,
    RegisterSuccess,
  ];

  private onEnd(data: any) {
    this.$router.push('/');
  }
}
