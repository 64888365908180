


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { AlertState } from '@/store/alert/types';
import { RootState } from '@/store/types';
import store from '@/store/store';

const namespace: string = 'alert';

@Component
export default class Alert extends Vue {
  @State('alert') private alertState: AlertState;
  @Prop(Boolean) private fixed: boolean;
  @Prop(Boolean) private autoHide: boolean;
  @Getter('successMessage', { namespace }) private successMessage?: string;
  @Getter('errorMessage', { namespace }) private errorMessage?: string;

  public updated() {
    if (this.alertState.visible) {
      // (this.$refs.test as HTMLElement).scrollIntoView({
      //   behavior: 'smooth',
      // });
      if (this.autoHide) {
        clearInterval(this.alertState.timeout);
        this.alertState.timeout = setTimeout(() => {
          store.commit('alert/HIDE');
        }, 5000);
      }
    }
  }
}
