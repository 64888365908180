export enum LOG_KEY {
  DATA_RECEIVED_FROM_DEVICE = 'data_received_from_device',
  ALARM_RECEIVED_FROM_DEVICE = 'alarm_received_from_device',
  DATA_SENT_TO_DEVICE = 'data_sent_to_device',
  ALARM_SENT_TO_TS = 'alarm_sent_to_ts',
  ALARM_RECEIVED_FROM_TS_ACK = 'alarm_received_from_ts_ack',
  ALARM_RECEIVED_FROM_TS_DUH = 'alarm_received_from_ts_duh',
  ALARM_NO_RECEIVED_FROM_TS = 'alarm_no_received_from_ts',
  NOTIFICATION_MAIL_SENT = 'notification_mail_sent',
}

export interface SimpleLog {
  date: string;
  information: string;
  data?: { [key: string]: any };
  key?: string;
  more?: string;
}

export interface LogDocument {
  key?: LOG_KEY;
  time?: number;
  data: { [key: string]: any };
}

export interface LogState {
  logs?: LogDocument[];
  noMoreLog: boolean;
}
