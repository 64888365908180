import { Module } from 'vuex';
import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { LogState } from './types';

export const state: LogState = {
  logs: [],
  noMoreLog: false,
};

const namespaced: boolean = true;

export const log: Module<LogState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
