



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Mutation, State, Getter } from 'vuex-class';
import { UserState, User } from '@/store/user/types';
import { WaveState } from '@/store/wave/types';
import { RootState } from '@/store/types';
import Store from '@/store/store';
import router from '../router';

const namespace: string = 'user';

@Component
export default class SideBar extends Vue {
  @Action('logout', { namespace }) private logout?: () => void;
  @Action('get', { namespace }) private get: () => void;
  @State('user') private userState?: UserState;
  @State('wave') private waveState?: WaveState;
  @State('menuVisible') private menuVisible: boolean;
  @Mutation('closeMenu') private closeMenu: () => void;
  @Mutation('toggleMenu') private toggleMenu: () => void;
  @Getter('userDataDevices', { namespace })
  private userDataDevices?: Array<any>;

  public isOpen: Boolean = true;

  get userData() {
    if (this.userState!.user) {
      return this.userState!.user;
    } else {
      return {};
    }
  }

  mounted() {
    this.get();
  }

  public onOpen() {
    this.isOpen = !this.isOpen;
  }

  get CurrentPage() {
    return this.$route.path;
  }
}
