


























import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';
import { WaveState } from '@/store/wave/types';
import i18n from '@/i18n';
import { NavWaveConfig } from '../../views/Wave.vue';

@Component
export default class DropWave extends Vue {
  @State('alert') private alertState?: any;
  @State('wave') private waveState?: WaveState;
  @Action('get', { namespace: 'wave' }) private get: (_id: string) => void;

  // Drop wave configuration
  @Prop(Array) private config: Array<NavWaveConfig>;

  // Label
  private label: string = i18n.t('menu').toString();

  isOpen: Boolean = false;

  private arraySelected: Array<number> = new Array();

  @Emit('onClick')
  public onClick(index: number, config: NavWaveConfig) {}

  public openDropdown() {
    this.isOpen = true;
    const dropDown: HTMLElement = this.$refs.dropDown as HTMLElement;
    if (dropDown) dropDown.focus();
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  public onSelect(index: number, config: NavWaveConfig) {
    this.isOpen = false;
    this.label = config.name || '';

    // Get device
    if (this.waveState && this.waveState.wave && this.waveState.wave._id)
      this.get(this.waveState.wave._id);

    this.alertState.visible = false;
    this.onClick(index, config);
  }
}
