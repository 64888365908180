












import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { AlertState } from '@/store/alert/types';
import { RootState } from '@/store/types';
import store from '@/store/store';

@Component
export default class LogMore extends Vue {
  @Prop({ type: String }) content: string;

  private isVisible: boolean = false;
  private marginTop: number = 0;
  private marginLeft: number = 0;

  private onMouseOver(event: MouseEvent) {
    this.isVisible = true;
  }

  private onMouseLeave() {
    this.isVisible = false;
    this.marginTop = 0;
    this.marginLeft = 0;
  }

  public updated() {
    if (!this.isVisible) return;
    let span = this.$refs.content as HTMLElement;
    if (span.getBoundingClientRect().right > window.innerWidth) {
      this.marginLeft =
        window.innerWidth - span.getBoundingClientRect().right - 10;
    }
    if (span.getBoundingClientRect().bottom > window.innerHeight) {
      this.marginTop =
        window.innerHeight - span.getBoundingClientRect().bottom - 10;
    }
  }
}
