






import { Component } from 'vue-property-decorator';
import AbstractPageComponent from '@/components/AbstractPageComponent.vue';

@Component({
  components: {},
})
export default class RegisterSuccess extends AbstractPageComponent {
  next(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
}
