









import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { WaveState, Wave } from '@/store/wave/types';
import BannerWave from '@/components/wave/BannerWave.vue';
import MapWave from '@/components/wave/MapWave.vue';
import NavWave from '@/components/wave/NavWave.vue';
import DropWave from '@/components/wave/DropWave.vue';

const namespace: string = 'wave';

@Component({
  components: {
    MapWave,
    BannerWave,
    NavWave,
    DropWave,
  },
})
export default class MapView extends Vue {
  @State('wave') private waveState?: any;
  @Prop(Object) private data?: any;
  @Prop(Object) private wave?: any;

  mapData: any = {
    location: {
      position: {
        type: 'Point',
        coordinates: [2.2020664744377467, 48.94450231827641],
      },
      radius: 5347,
    },
  };
  design: any = {
    icon: 'fas fa-map',
    isMap: true,
    name: 'map',
  };
}
