










































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Mutation, Getter } from 'vuex-class';
import { WaveState } from '@/store/wave/types';
import i18n from '@/i18n';

const namespace: string = 'wave';

@Component
export default class WaitingDownlink extends Vue {
  @State('wave') private waveState?: WaveState;
  @Action('deleteDownlink', { namespace }) private deleteDownlink: (
    idDevice: string,
  ) => Promise<any>;
  @Action('get', { namespace }) private get: (idDevice: string) => void;
  @Getter('nextDownloadTime', { namespace })
  private nextDownloadTime?: number;

  private isDetailsVisible: boolean = false;

  mounted() {}

  onMouseEnter() {
    this.isDetailsVisible = true;
  }
  onMouseLeave() {
    this.isDetailsVisible = false;
  }

  onRemovePendingConfiguration() {
    if (confirm(i18n.t('confirmRemovePendingConfiguration').toString())) {
      if (
        this.waveState != null &&
        this.waveState.wave != null &&
        this.waveState.wave._id != null
      ) {
        this.deleteDownlink(this.waveState.wave._id)
          .then(() => {
            this.get(this.waveState!.wave!._id!);
          })
          .catch(() => {});
      }
    }
    this.isDetailsVisible = false;
  }

  isActive(): boolean {
    if (
      this.waveState != null &&
      this.waveState.wave != null &&
      this.waveState.wave.downlinkData != null
    )
      return this.waveState.wave.downlinkData.active === true;
    else return false;
  }
}
