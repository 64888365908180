






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AlarmHistory from '@/components/wave/AlarmHistory.vue';

const namespace: string = 'wave';

@Component({
  components: {
    AlarmHistory,
  },
})
export default class AlarmHistoryView extends Vue {}
