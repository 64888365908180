



































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';

@Component
export default class Dropdown extends Vue {
  @Prop(Array) private options: Array<
    { text: string; value?: string | number } | string
  >;
  @Prop([String, Number]) private value: string | number;

  isOpen: Boolean = false;

  private arraySelected: Array<number> = new Array();

  public switchDropdown() {
    this.isOpen = !this.isOpen;
    const dropDown: HTMLElement = this.$refs.dropDown as HTMLElement;
    if (this.isOpen && dropDown) {
      dropDown.focus();
    }
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  private getSelectedText(
    value: { text: string; value?: string | number } | string,
  ): string {
    let option = this.options.filter((o) =>
      typeof o === 'string' ? o === value : o.value === value,
    );
    if (option.length === 1) {
      if (typeof option[0] === 'string') return option[0] as string;
      else return (option[0] as { text: string; value?: string | number }).text;
    } else return '';
  }

  @Emit('input')
  onSelect(option: any): any {
    this.closeDropdown();
    return option.value != null ? option.value : option.text;
  }
}
