import store from '@/store/store';
import { Error } from '@/store/types';
import { MutationTree } from 'vuex';
import { LogDocument, LogState } from './types';

export const mutations: MutationTree<LogState> = {
  LOG_SUCCESS: (state, payload: LogDocument[]) => {
    state.noMoreLog = false;
    state.logs = payload;
  },
  LOG_SUCCESS_MORE: (state, payload: LogDocument[]) => {
    state.noMoreLog = false;
    state.logs = [...(state.logs || []), ...payload];
  },
  LOG_SUCCESS_NO_MORE: (state, payload: LogDocument[]) => {
    state.noMoreLog = true;
  },
  LOG_ERROR: (state, payload: Error) => {
    state.logs = [];
    store.commit('alert/SHOW', {
      prefix: 'LOG_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
};
