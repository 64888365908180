



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { WaveState, Wave } from '@/store/wave/types';
import BannerWave from '@/components/wave/BannerWave.vue';
import NavWave from '@/components/wave/NavWave.vue';
import NavWave2 from '@/components/wave/NavWave2.vue';
import DropWave from '@/components/wave/DropWave.vue';
import Graphic from '@/components/graph/Graphic.vue';
import Historic from '@/components/graph/Historic.vue';
import Notification from '@/components/graph/Notification.vue';
import ConfigurationTemperature from '@/components/graph/ConfigurationTemperature.vue';
import i18n from '@/i18n';
import { NavWaveConfig } from '../../../views/Wave.vue';

const namespace: string = 'wave';

@Component({
  components: {
    BannerWave,
    NavWave,
    NavWave2,
    DropWave,
    Graphic,
    Historic,
    Notification,
    ConfigurationTemperature,
  },
})
export default class TemperatureView extends Vue {
  // @State('wave') private waveState?: any;
  // @Prop(Object) private data: any;
  // @Prop(Object) private wave: Wave;
  @Prop(Number) private no: number;
  @State('wave') private waveState?: any;

  private view: String = '';
  private navWaveSelectedIndex: number = 0;

  private navWaveConfig: Array<any> = [
    {
      name: i18n.t('configuration'),
      component: 'ConfigurationTemperature',
    },
  ];

  design: any = {
    icon: 'fas fa-thermometer-half',
    isTemperature: true,
    name: 'temperature',
  };

  mounted() {
    this.view = this.navWaveConfig[0].component;
  }

  private onClick(index: number, config: NavWaveConfig) {
    this.view = config.component || '';
    // this.data = config.data;
    this.navWaveSelectedIndex = index;
  }
}
