import { render, staticRenderFns } from "./ConfigurationInput.vue?vue&type=template&id=1626975a&scoped=true&"
import script from "./ConfigurationInput.vue?vue&type=script&lang=ts&"
export * from "./ConfigurationInput.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigurationInput.vue?vue&type=style&index=0&id=1626975a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1626975a",
  null
  
)

export default component.exports