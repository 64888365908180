






import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { LoadingState } from '@/store/loading/types';
import { RootState } from '@/store/types';

const namespace: string = 'loading';

@Component
export default class Loading extends Vue {
  @State('loading') private loadingState?: LoadingState;

  constructor() {
    super();
  }
}
