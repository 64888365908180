










































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';

const namespace: string = 'wave';

@Component
export default class BannerWave extends Vue {
  @Prop(Object) private data: any;
  @Prop(Object) private design: any;
  // @Prop(Object) private wave: any;
  @State('wave') private waveState?: any;
  @Getter('isDownlinkPendingValue', { namespace })
  private isDownlinkPendingValue: boolean;

  @Getter('nextDownloadTime', { namespace })
  private nextDownloadTime?: number;

  get value() {
    return this.data;
  }

  @Emit('onPut')
  onPut(value: Boolean) {
    return value;
  }

  isActive(): boolean {
    if (
      this.waveState != null &&
      this.waveState.wave != null &&
      this.waveState.wave.downlinkData != null
    )
      return this.waveState.wave.downlinkData.active === true;
    else return false;
  }

  public dateConvert(date: any) {
    let d = new Date(date);
    return `${d.toLocaleDateString()} - ${d.toLocaleTimeString()}`;
  }
}
