




import { Component, Vue, Watch } from 'vue-property-decorator';
import { WaveState } from '@/store/wave/types';
import { UserState } from '@/store/user/types';
import { State, Getter } from 'vuex-class';

@Component
export default class Home extends Vue {
  @State('wave') private waveState: WaveState;
  @State('user') private userState: UserState;
  @Getter('userDataDevices', { namespace: 'user' })
  private userDataDevices: Array<any>;
}
