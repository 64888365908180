

























import { Component, Prop, Vue, Model, Emit } from 'vue-property-decorator';

@Component
export default class Input extends Vue {
  @Prop(String) private name: String;
  @Prop(String) private step: String;
  @Prop(String) private label: String;
  @Prop(String) private help: String;
  @Prop(Boolean) private required: boolean;
  @Prop(Boolean) private disabled: boolean;
  @Prop([String, Number]) private value?: String | Number;
  @Prop(String) private type: String;
  @Prop(String) private pattern: String;
  @Prop(Number) private maxLength: Number;
  @Prop(Number) private max: Number;
  @Prop(Number) private min: Number;
  @Prop(String) private autocomplete: String;
  @Prop(String) private title: String;

  private hasFocus: boolean = false;

  get isActive(): boolean {
    return (
      this.value !== '' ||
      (this.hasFocus && (this.value === '' || this.value === undefined))
    );
  }

  private onFocus() {
    this.hasFocus = true;
  }

  private onBlur() {
    this.hasFocus = false;
  }
}
