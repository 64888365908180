















import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { IMessage } from '@/store/alert/types';
import { UserState, User } from '@/store/user/types';
import Alert from '@/components/Alert.vue';
import Input from '@/components/Input.vue';

const namespace: string = 'user';

@Component({
  components: {
    Alert,
    Input,
  },
})
export default class LostPassword extends Vue {
  @Action('lostPassword', { namespace }) private lostPassword: (
    user: User,
  ) => Promise<undefined>;
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @State('user') private userState?: UserState;
  private user: any = { email: '' };

  public validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  }

  public goBack() {
    this.$router.push('/login');
  }

  private onChangePassword() {
    if (this.user.email !== '') {
      if (this.validateEmail(String(this.user.email))) {
        this.lostPassword(this.user)
          .then(() => {
            this.user.email = '';
          })
          .catch(() => {});
      } else {
        this.alert({
          prefix: 'INPUT_',
          error: 'NOT_EMAIL',
        });
      }
    } else {
      this.alert({
        prefix: 'INPUT_',
        error: 'EMPTY',
      });
    }
  }
}
