
































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { State, Action, Mutation, Getter } from 'vuex-class';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import CheckBox from '@/components/CheckBox.vue';
import Dropdown from '@/components/Dropdown.vue';
import Input from '@/components/Input.vue';
import SwitchBox from '@/components/SwitchBox.vue';
import BoxReport from '@/components/wave/BoxReport.vue';
import SelectControl from '@/components/SelectControl.vue';

import {
  WaveState,
  Wave,
  ReportDocument,
  DeviceInputDocument,
} from '@/store/wave/types';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    CheckBox,
    Dropdown,
    BoxReport,
    SwitchBox,
    SelectControl,
  },
})
export default class ConfigurationTemperature extends Vue {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @State('wave') private waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('putPort', { namespace }) private put: (
    value: any,
  ) => Promise<undefined>;
  @Action('putNotif', { namespace }) private putNotif: (
    value: any,
  ) => Promise<undefined>;

  @Getter('isDownlinkPendingValue', { namespace })
  private isDownlinkPendingValue: boolean;

  @Prop(Object) private data: DeviceInputDocument;

  private arrayTypeInput: Array<any> = [
    { text: 'normally_close', value: 0 },
    { text: 'normally_open', value: 1 },
    { text: 'temperature', value: 2 },
  ];

  onPutNotif() {
    let value = {
      _id: this.waveState.wave._id,
      type: 'inputs',
      notification: this.data.notification,
      no: this.data.no,
    };
    this.putNotif(value)
      .then(() => {
        this.getWave(value._id);
      })
      .catch((error) => {});
  }

  onPut() {
    let port = {};
    port = {
      name: this.data.name,
      no: Number(this.data.no),
      type: Number(this.data.type),
      calibrate: this.data.calibrate,
    };

    let value = {
      _id: this.waveState.wave._id,
      type: 'inputs',
      port: port,
    };
    if (this.data.name !== '') {
      this.put(value)
        .then(() => {
          this.getWave(value._id);
        })
        .catch((error) => {});
    } else {
      this.alert({
        prefix: 'INPUT_',
        error: 'EMPTY',
      });
    }
  }
}
