





















import Alert from '@/components/Alert.vue';
import BoxReport from '@/components/wave/BoxReport.vue';
import { IMessage } from '@/store/alert/types';
import { Component, Emit, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';

const namespace: string = 'wave';

@Component({
  components: {
    BoxReport,
    Alert,
  },
})
export default class ConfigWave extends Vue {
  @Mutation('alert/SHOW') private axlert: (alertMessage: IMessage) => void;
  @State('wave') private waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('putReport', { namespace }) private put: (
    value: any,
  ) => Promise<undefined>;

  onPut() {
    let value = {
      _idDevice: this.waveState.wave._id,
      reports: this.waveState.wave.reports,
    };
    this.put(value)
      .then(() => {
        this.getWave(value._idDevice);
      })
      .catch((error) => {});
  }

  @Emit('onClose')
  private onClose() {
    return false;
  }
}
