






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConfigWave from '@/components/wave/ConfigWave.vue';

const namespace: string = 'wave';

@Component({
  components: {
    ConfigWave,
  },
})
export default class ConfigView extends Vue {}
