












































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SelectControl from '@/components/SelectControl.vue';
import { State, Action, Getter, Mutation } from 'vuex-class';
import {
  WaveState,
  Wave,
  DeviceAutosurveyDocument,
  DeviceAutosurveyNotificationDocument,
} from '@/store/wave/types';
import store from '@/store/store';
import { RootState } from '@/store/types';
import Input from '@/components/Input.vue';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    SelectControl,
  },
})
export default class AutoView extends Vue {
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @State('wave')
  private waveState?: any;
  @Getter('autosurveyNotifications', { namespace })
  private autosurveyNotifications?: Array<DeviceAutosurveyNotificationDocument>;
  @Action('putAutoSurvey', { namespace }) private put: (
    wave: Wave,
  ) => Promise<undefined>;

  mounted() {
    if (this.waveState.wave.imei) {
      this.surveyNotificationColumns.push('surveyNotificationSms');
    }
  }

  private surveyNotificationColumns: Array<string> = [
    'surveyNotificationCode',
    'surveyNotificationText',
    'surveyNotificationFilter',
    'surveyNotificationEmails',
  ];

  private surveyGroupColumns: Array<string> = [
    'surveyGroupCode',
    'surveyGroupName',
  ];
  private surveyZoneColumns: Array<string> = [
    'surveyZoneCode',
    'surveyZoneName',
  ];
  private surveyUserColumns: Array<string> = [
    'surveyUserCode',
    'surveyUserName',
  ];

  private onNotificationDelete(index: number) {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.notifications
    )
      this.waveState.wave.autosurvey.notifications.splice(index, 1);
  }

  private onGroupDelete(index: number) {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.groups
    )
      this.waveState.wave.autosurvey.groups.splice(index, 1);
  }

  private onZoneDelete(index: number) {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.zones
    )
      this.waveState.wave.autosurvey.zones.splice(index, 1);
  }

  private onUserDelete(index: number) {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.users
    )
      this.waveState.wave.autosurvey.users.splice(index, 1);
  }

  private addNotification() {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.notifications
    ) {
      let emails =
        (
          this.waveState.wave.autosurvey.notifications[
            this.waveState.wave.autosurvey.notifications.length - 1
          ] || { emails: [] }
        ).emails || [];
      this.waveState.wave.autosurvey.notifications.push({
        emails: [...emails],
      });
    }
  }

  private addGroup() {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.groups
    )
      this.waveState.wave.autosurvey.groups.push({});
  }

  private addZone() {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.zones
    )
      this.waveState.wave.autosurvey.zones.push({});
  }

  private addUser() {
    if (
      this.waveState.wave &&
      this.waveState.wave.autosurvey &&
      this.waveState.wave.autosurvey.users
    )
      this.waveState.wave.autosurvey.users.push({});
  }

  private onSaveNotification() {
    let idWave = this.waveState.wave._id;
    this.put(this.waveState.wave).then(() => {
      this.getWave(idWave);
    });
  }
}
