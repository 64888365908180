














































































































import Alert from '@/components/Alert.vue';
import CheckBox from '@/components/CheckBox.vue';
import DropDown from '@/components/Dropdown.vue';
import Input from '@/components/Input.vue';
import SwitchBox from '@/components/SwitchBox.vue';
import ConfigAdvancedWave from '@/components/wave/ConfigAdvancedWave.vue';
import ConfigAlarmCode from '@/components/wave/ConfigAlarmCode.vue';
import { IMessage } from '@/store/alert/types';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';

const namespace: string = 'wave';

@Component({
  components: {
    Input,
    Alert,
    CheckBox,
    DropDown,
    SwitchBox,
    ConfigAdvancedWave,
    ConfigAlarmCode,
  },
})
export default class ConfigWave extends Vue {
  @Mutation('alert/SHOW') private axlert: (alertMessage: IMessage) => void;
  @State('wave') private waveState?: any;
  @Action('get', { namespace }) private getWave: (_id: string) => void;
  @Action('putAlarmCode', { namespace }) private putAlarmCode: (
    value: any,
  ) => Promise<undefined>;

  public codes(): Array<string> {
    let codes = [
      'startingAlarmCode',
      'lowerVoltageAlarmCode',
      'thresholdCrossingAlarmCode',
      'supervisionAlarmCode',
      'rssiAlarmCode',
    ];
    let sigfoxCodes = ['sigfoxOverDataAlarmCode'];
    if (this.waveState.wave.imei != null) return codes;
    else return [...codes, ...sigfoxCodes];
  }

  onPut() {
    this.putAlarmCode(this.waveState.wave)
      .then(() => {
        this.getWave(this.waveState.wave._id);
      })
      .catch((error) => {});
  }
}
