
























import { Component, Prop, Vue, Model, Emit } from 'vue-property-decorator';

@Component
export default class SelectControl extends Vue {
  // @Prop({ type: Array, default: () => [] }) private items: Array<string>;
  @Prop({ type: Array, default: () => [] }) private value: Array<string>;
  @Prop(String) private type?: string;
  @Prop(String) private placeholder?: string;

  private inputValue: string = '';
  private minWidth: number = 0;
  private items: Array<string> = [];

  mounted() {
    if (this.$refs.label) {
      this.minWidth = (this.$refs.label as HTMLInputElement).clientWidth;
    }
    if (Array.isArray(this.value)) {
      this.items = this.value;
    }
  }

  public onBlur() {
    this.onEnter();
    this.inputValue = '';
  }

  @Emit('input')
  private onEnter(e?: KeyboardEvent | null) {
    if (this.inputValue.trim().length > 0) {
      if (this.items.indexOf(this.inputValue.trim()) === -1) {
        let isValid: boolean = true;
        if (this.type === 'email')
          isValid = this.isEmail(this.inputValue.trim());
        else if (this.type === 'phone')
          isValid = this.isPhoneNumber(this.inputValue.trim());
        else if (this.type === 'phoneOnlyNumeric')
          isValid = this.isPhoneNumberOnlyNumeric(this.inputValue.trim());
        if (isValid) {
          this.items.push(this.inputValue);
          this.inputValue = '';
          // this.inputWidth = 1;
        }
      }
    }
    return this.items;
  }

  private isEmail(value: string): boolean {
    return (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(
        value,
      ) !== null
    );
  }

  private isPhoneNumber(value: string): boolean {
    return /^((?:\+\d{11,})|(?:00\d{11,})|(?:0\d{9,}))$/.exec(value) !== null;
  }

  private isPhoneNumberOnlyNumeric(value: string): boolean {
    return /^((?:00\d{11,})|(?:0\d{9,}))$/.exec(value) !== null;
  }

  private onDelete(e: KeyboardEvent) {
    if (this.inputValue.length === 0) this.items.pop();
  }

  private onKeydown(e: KeyboardEvent) {
    // if (this.$refs.label)
    //   this.inputWidth = (this.$refs.label as HTMLInputElement).clientWidth;
  }

  private onDeleteItem(i: number) {
    this.items.splice(i, 1);
    this.$emit('input', this.items);
  }
}
