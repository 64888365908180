
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';
import AbstractPageComponent from './AbstractPageComponent.vue';

@Component({
  components: {
    Alert,
  },
})
export default class Tutorial extends Vue {
  @Prop(Array) private data: Array<typeof AbstractPageComponent>;
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;

  public stepIndex: number = 0;

  get isSelect() {
    return this.data[this.stepIndex];
  }

  private hasPrevStep() {
    return this.stepIndex > 0;
  }
  private hasNextStep() {
    return this.stepIndex < this.data.length - 1;
  }
  private isFirstStep() {
    return this.stepIndex === 0;
  }

  private isFinishStep() {
    return this.stepIndex === this.data.length - 1;
  }

  public stepIsActive(index: any) {
    if (this.stepIndex === index) {
      return 'isActive';
    } else {
      return 'isNotActive';
    }
  }

  private goBack() {
    this.$router.go(-1);
  }

  private onPrev() {
    this.stepIndex--;
  }

  private onNext() {
    (this.$refs.activeComponent as AbstractPageComponent)
      .next()
      .then(() => {
        this.stepIndex++;
      })
      .catch(() => {
        // this.alert({
        //   prefix: 'EVENT_',
        //   error: 'DIDAC',
        // });
      });
  }

  private onFinish() {
    (this.$refs.activeComponent as AbstractPageComponent)
      .next()
      .then((data) => {
        this.$emit('end', data);
        // return this.$router.push('/');
      })
      .catch(() => {
        // this.alert({
        //   prefix: 'EVENT_',
        //   error: 'DIDAC',
        // });
      });
  }
}
