


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { UserState, User } from '@/store/user/types';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';
import Input from '@/components/Input.vue';

const namespace: string = 'user';

@Component({
  components: {
    Alert,
    Input,
  },
})
export default class LoginForm extends Vue {
  @Action('login', { namespace }) private login: (user: any) => void;
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @Mutation('modal/SHOW') private modal: (view: any) => void;
  @State('user') private userState?: UserState;
  private user: any = { email: '', password: '' };

  public validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  }

  private onRegister() {
    this.modal({ view: 'register-form' });
    this.$router.push('/register');
  }

  private onLogin() {
    if (this.validateEmail(this.user.email!)) {
      this.login(this.user);
    } else {
      this.alert({
        prefix: 'INPUT_',
        error: 'NOT_EMAIL',
      });
    }
  }
}
