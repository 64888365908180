import router from '@/router';
import session from '@/session';
import store from '@/store/store';
import { Error } from '@/store/types';
import { MutationTree } from 'vuex';
import { UserState, User } from './types';

export const mutations: MutationTree<UserState> = {
  USER_SUCCESS: (state, payload: User) => {
    state.user = payload;
  },
  USER_ERROR: (state, payload: Error) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'USER_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  LOGIN_SUCCESS: (state, payload: User) => {
    state.user = payload;
    session.create(payload);

    if (Array.isArray(payload.devices) && payload.devices.length > 0) {
      router.push(`/wave/${payload.devices[0]._id}`);
    } else {
      router.push('/');
    }
  },
  LOGIN_ERROR: (state, payload: Error) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'LOGIN_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  LOGOUT_SUCCESS: (state) => {
    state.user = undefined;
    session.destroy();
    router.push('/login');
  },
  LOGOUT_ERROR: (state, payload: Error) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'LOGIN_ERROR_',
      error: payload.statusText || payload.status,
    });
    session.destroy();
    router.push('/login');
  },
  LOGIN_DISCONNECTED: (state, payload: Error) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'LOGIN_ERROR_',
      error: payload.statusText || payload.status,
    });
    session.destroy();
    router.replace('/login');
  },
  REGISTER_SUCCES: () => {
    router.push('/login');
  },
  REGISTER_ERROR: (state, payload: Error) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'REGISTER_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  ACTIVATION_SUCCESS: (state, payload: User) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'ACTIVATION_',
      success: 'SUCCESS',
    });
  },
  ACTIVATION_ERROR: (state, payload: Error) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'ACTIVATION_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  ACTIVATION_EMAIL_SUCCESS: (state, payload: User) => {
    state.user = undefined;
    store.commit('alert/SHOW', {
      prefix: 'ACTIVATION_EMAIL_',
      success: '200',
    });
  },
  ACTIVATION_EMAIL_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'ACTIVATION_EMAIL_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  LOST_PASSWORD_SUCCESS: () => {
    store.commit('alert/SHOW', {
      prefix: 'LOST_PASSWORD_SUCCESS_',
      success: '200',
    });
  },
  LOST_PASSWORD_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'LOST_PASSWORD_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  CHANGE_PASSWORD_SUCCESS: () => {
    store.commit('alert/SHOW', {
      prefix: 'CHANGE_PASSWORD_SUCCESS_',
      success: '200',
    });
  },
  CHANGE_PASSWORD_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'CHANGE_PASSWORD_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  CHANGE_EMAIL_SUCCESS: () => {
    store.commit('alert/SHOW', {
      prefix: 'CHANGE_EMAIL_SUCCESS_',
      success: '200',
    });
  },
  CHANGE_EMAIL_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'CHANGE_EMAIL_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  USER_UPDATE_SUCCESS: () => {
    store.commit('alert/SHOW', {
      prefix: 'USER_UPDATE_SUCCESS_',
      success: '200',
    });
  },
  USER_UPDATE_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'USER_UPDATE_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  DELETE_ACCOUNT_SUCCESS: (state) => {
    store.commit('alert/SHOW', {
      prefix: 'DELETE_ACCOUNT_SUCCESS_',
      success: '200',
    });
  },
  DELETE_ACCOUNT_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'DELETE_ACCOUNT_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
  USER_DELETED_SUCCESS: (state) => {
    store.commit('alert/SHOW', {
      prefix: 'USER_DELETED_SUCCESS_',
      success: '200',
    });
  },
  USER_DELETED_ERROR: (state, payload: Error) => {
    store.commit('alert/SHOW', {
      prefix: 'USER_DELETED_ERROR_',
      error: payload.statusText || payload.status,
    });
  },
};
