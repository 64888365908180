
















import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { IMessage } from '@/store/alert/types';
import { RootState } from '@/store/types';
import Alert from '@/components/Alert.vue';
import Input from '@/components/Input.vue';
import AbstractPageComponent from '@/components/AbstractPageComponent.vue';

@Component({
  components: {
    Alert,
    Input,
  },
})
export default class AddWavegateway extends AbstractPageComponent {
  @Mutation('alert/SHOW') private alert: (alertMessage: IMessage) => void;
  @Mutation('alert/HIDE') private alertOff: () => void;
  @Action('get', { namespace: 'user' }) private get: () => void;
  @Action('post', { namespace: 'wave' }) private post: (
    code: string,
  ) => Promise<undefined>;

  public waveCode: string = '';

  mounted() {
    this.alertOff();
  }

  next(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.waveCode !== '') {
        this.post(this.waveCode)
          .then((data) => {
            this.get();
            resolve(data);
          })
          .catch((error) => {
            // alert(error);
            reject();
          });
      } else {
        this.alert({
          prefix: 'INPUT_',
          error: 'EMPTY',
        });
      }
    });
  }
}
