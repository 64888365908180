import { GetterTree } from 'vuex';
import { AlarmState, SimpleAlarm, AlarmDocument, ALARM_TYPE } from './types';
import { RootState } from '@/store/types';
import i18n from '@/i18n';

export const getters: GetterTree<AlarmState, RootState> = {
  simpleAlarms: (state): SimpleAlarm[] => {
    let simpleAlarms: Array<SimpleAlarm> = [];

    let date = '';
    let information = '';

    for (const alarm of state.alarms || []) {
      // Attempts
      for (const attempt of alarm.attempts || []) {
        if (attempt.time != null) {
          date = `${new Date(attempt.time).toLocaleDateString()} ${new Date(
            attempt.time,
          ).toLocaleTimeString()}`;
        } else if (alarm.time != null) {
          date = `${new Date(alarm.time).toLocaleDateString()} ${new Date(
            alarm.time,
          ).toLocaleTimeString()}`;
        }

        simpleAlarms.push({
          date,
          information:
            attempt.response.includes('ACK') ||
            attempt.response.includes(String.fromCharCode(0x06))
              ? i18n.t('alarm-server-ack').toString()
              : i18n.t('alarm-server-nack').toString(),
        });
      }

      // Alarm message receive
      if (
        alarm.time != null &&
        (alarm.type === ALARM_TYPE.TS || alarm.type == null)
      ) {
        date = `${new Date(alarm.time).toLocaleDateString()} ${new Date(
          alarm.time,
        ).toLocaleTimeString()}`;
        information = i18n.t('alarm-message-reception').toString();
        simpleAlarms.push({
          date,
          information,
        });
      } else if (alarm.time != null && alarm.type === ALARM_TYPE.MAIL) {
        date = `${new Date(alarm.time).toLocaleDateString()} ${new Date(
          alarm.time,
        ).toLocaleTimeString()}`;

        information = i18n
          .t('alarm-mail-sent', {
            bcc:
              alarm != null && alarm.mail != null
                ? (alarm.mail.bcc || []).join(', ')
                : '',
          })
          .toString();
        simpleAlarms.push({
          date,
          information,
        });
      }
    }
    return simpleAlarms;
  },
};
