import { GetterTree } from 'vuex';
import { WaveState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<WaveState, RootState> = {
  isDownlinkPendingValue: (state) => (value: string): boolean => {
    return state.downlinkPendingValues.indexOf(value) > -1;
  },
  isPendingReboot: (state: WaveState) => (): boolean => {
    return (
      state.wave != null &&
      state.wave.downlinkData != null &&
      state.wave.downlinkData.payload != null &&
      state.wave.downlinkData.payload.reboot === true
    );
  },
  isPendingFactoryReset: (state: WaveState) => (): boolean => {
    return (
      state.wave != null &&
      state.wave.downlinkData != null &&
      state.wave.downlinkData.payload != null &&
      state.wave.downlinkData.payload.factoryReset === true
    );
  },
  autosurveyNotifications: (state) => {
    if (
      state &&
      state.wave &&
      state.wave.autosurvey &&
      Array.isArray(state.wave.autosurvey.notifications)
    )
      return state.wave.autosurvey.notifications;
    else return [];
  },
  nextDownloadTime: (state) => {
    if (
      state &&
      state.wave &&
      state.wave.downlinkData &&
      state.wave.downlinkData.nextDownloadTime
    )
      return new Date(state.wave.downlinkData.nextDownloadTime);
  },
};
